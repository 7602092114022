const mbrowser = function () {
    this.spec_string = navigator.userAgent
    this.name = this.get_name()
    this.version = this.get_version()
}

mbrowser.prototype.get_name = function () {
    var spec_string = this.spec_string

    var matches = spec_string.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []

    // Work with the matches.
    matches = matches[2] ? [matches[1], matches[2]] : [navigator.appName, navigator.appVersion, "-?"]
    let temp = ""

    // Trident.
    if (/trident/i.test(matches[1])) {
        temp = /\brv[ :]+(\d+)/g.exec(spec_string) || []
        return "IE"
    }

    // Chrome.
    if (matches[1] === "Chrome") {
        temp = spec_string.match(/\bOPR|Edge\/(\d+)/)
        if (temp != null) {
            return "Opera"
        }
    }

    if ((temp = spec_string.match(/version\/(\d+)/i)) != null) {
        matches.splice(1, 1, temp[1])
    }

    return matches[0]
}

mbrowser.prototype.get_version = function () {
    var spec_string = this.spec_string

    var matches = spec_string.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []

    // Work with the matches.
    matches = matches[2] ? [matches[1], matches[2]] : [navigator.appName, navigator.appVersion, "-?"]
    let version = ""
    let temp = ""

    // Trident.
    if (/trident/i.test(matches[1])) {
        temp = /\brv[ :]+(\d+)/g.exec(spec_string) || []
        version = temp[1] || ""
        return version
    }

    // Chrome.

    if (matches[1] === "Chrome") {
        temp = spec_string.match(/\bOPR|Edge\/(\d+)/)
        version = temp[1]
        if (temp != null) {
            return version
        }
    }

    if ((temp = spec_string.match(/version\/(\d+)/i)) != null) {
        matches.splice(1, 1, temp[1])
    }

    version = matches[1]

    return version
}

export default {
    data() {
        return {
            mbrowser: new mbrowser()
        }
    }
}
