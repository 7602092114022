<template>
    <div class="file-preview">
        <div class="file-preview-container">
            <div ref="content" class="file-preview-content" :class="file.extension">
                <template v-if="mount">
                    <iframe
                        v-if="
                            file.extension === 'doc' ||
                            file.extension === 'docx' ||
                            file.extension === 'ppt' ||
                            file.extension === 'pptx'
                        "
                        :src="`https://view.officeapps.live.com/op/embed.aspx?src=${file.url}`"
                        width="100%"
                        :height="`${getContentHeight()}px`"
                        frameBorder="0"
                    ></iframe>

                    <PdfViewer v-else-if="file.extension === 'pdf' && isNewViewerEnabled()" :path="file.url" />
                    <iframe
                        width="100%"
                        :height="`${getContentHeight()}px`"
                        v-else-if="file.extension === 'pdf'"
                        ref="frame"
                    ></iframe>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import PdfViewer from "@components/Files/PdfViewer.vue"
import mbrowser from "@mixins/mbrowser"

export default {
    components: { PdfViewer },
    props: {
        file: {
            type: Object,
            default: () => {}
        }
    },
    name: "FilePreview",
    mixins: [mbrowser],
    data() {
        return {
            mount: false
        }
    },
    mounted() {
        setTimeout(() => {
            this.mount = true

            this.$nextTick(() => {
                if (!this.isNewViewerEnabled() && this.file.extension === "pdf") {
                    this.$refs.frame.src = `https://docs.google.com/viewer?url=${this.file.url}&embedded=true`

                    const interval = setInterval(() => {
                        try {
                            // google docs page is blank (204), hence we need to reload the iframe.
                            if (this.$refs.frame.contentWindow.document.body.innerHTML === "") {
                                this.$refs.frame.src = `https://docs.google.com/viewer?url=${this.file.url}&embedded=true`
                            }
                        } catch (e) {
                            console.log(e)
                            // google docs page is being loaded, but will throw CORS error.
                            // it mean that the page won't be blank and we can remove the checking interval.
                            clearInterval(interval)
                        }
                    }, 2000)
                }
            })
        }, 0)
    },
    methods: {
        isNewViewerEnabled() {
            return this.mbrowser.name !== "Safari"
        },
        getContentHeight() {
            //this?.$el?.querySelector(".file-preview-content")?.getBoundingClientRect()?.height - 40 || 500
            return window.innerHeight
        }
    }
}
</script>

<style lang="sass" scoped>
.file-preview
    .file-preview-header
        height: 70px
        display: flex
        align-items: center
        justify-content: space-between
        padding: 0 34px 0 24px
    .file-preview-container
        height: 100%
        width: 100%
        flex: 1
        display: flex
        flex-direction: column
    .file-preview-content
        &.doc, &.docx
            flex: 1
            overflow: auto
</style>

