<template>
    <div :style="questionStyle()" class="practice-question">
        <PracticeQuestionInfo :number="questionNumber" :question="question" :quiz="quiz" :name="question.name" />

        <PracticeQuestionDefaultOptions
            v-if="interactive"
            :attempt="attempt"
            :quiz="quiz"
            :question="question"
            @next="$emit('next')"
        />
        <div v-else-if="isShowAnswers()" class="mb-10"></div>

        <template v-if="isShowAnswers()">
            <PracticeWordOrderStatement
                @callout="$emit('callout')"
                :class="$screenWidth > 500 ? '' : ''"
                class="mb-25"
                :statement="statement"
                :key="statement.id"
                :type-show="typeShow()"
                :interactive="interactive"
                :viewable="viewable"
                :readonly="readonly"
                :results="results"
                :question="question"
                :expert-view="expertView"
                :number="index + 1"
                :attempt="attempt"
                :lesson="lesson"
                v-for="(statement, index) in question.statements"
            >
                <div class="mt-2">
                    <AttemptResultStudentWidget
                        class="mr-10"
                        :attempt-id="attempt && attempt.id"
                        v-if="getCommentable(statement) && getCommentable(statement).has_comments && !expertView"
                        :commentable-id="getCommentable(statement).id"
                        :comments-count="getCommentable(statement).comments_count"
                        :commentable-type="'statement_id'"
                    />
                    <AttemptResultExpertWidget
                        class="mr-10"
                        :statement="statement"
                        v-bind="widget(statement)"
                        v-else-if="expertView"
                    />
                </div>
            </PracticeWordOrderStatement>
        </template>

        <div v-if="!interactive && typeShow() === 'full_information' && !expertView">
            <default-title class="mt-20 mb-20" :size="16" :weight="500" :line-height="21">
                {{ t("quiz.correct_answer") }}
            </default-title>

            <PracticeWordOrderStatement
                @callout="$emit('callout')"
                :class="$screenWidth > 500 ? 'ml-0' : ''"
                answers-key="answers"
                class="mb-25"
                :statement="statement"
                :key="statement.id"
                :type-show="typeShow()"
                :interactive="interactive"
                :viewable="viewable"
                :number="index + 1"
                :readonly="readonly"
                :results="results"
                :question="question"
                :expert-view="expertView"
                :attempt="attempt"
                :lesson="lesson"
                v-for="(statement, index) in question.statements"
            />
        </div>

        <PracticeCorrectStatusAlert
            v-if="!interactive"
            :is-correct="isCorrect"
            :is-incorrect="isIncorrect"
            :is-skipped="isSkipped"
            :is-not-started="isNotStarted"
        />

        <PracticeQuestionComment v-if="isDisplayComment" :question="question" />

        <PracticeActionsContainer :question="question" :quiz="quiz" v-if="interactive" v-on="$listeners" />
    </div>
</template>

<script>
import PracticeMixin from "@components/Practice/PracticeQuestions/_mixin"
import PracticeActionsContainer from "@components/Practice/PracticeQuestions/components/PracticeActionsContainer.vue"
import PracticeCorrectStatusAlert from "@components/Practice/PracticeQuestions/components/PracticeCorrectStatusAlert.vue"
import PracticeQuestionDefaultOptions from "@components/Practice/PracticeQuestions/components/PracticeQuestionDefaultOptions.vue"
import PracticeQuestionInfo from "@components/Practice/PracticeQuestions/components/PracticeQuestionInfo.vue"
import PracticeWordOrderStatement from "@components/Practice/PracticeQuestions/components/PracticeWordOrderStatement.vue"
import DefaultTitle from "@components/Typography/DefaultTitle.vue"
import shuffle from "lodash.shuffle"
import { v4 } from "uuid"

export default {
    components: {
        PracticeCorrectStatusAlert,
        DefaultTitle,
        PracticeActionsContainer,
        PracticeQuestionDefaultOptions,
        PracticeQuestionInfo,
        PracticeWordOrderStatement
    },
    mixins: [PracticeMixin],
    name: "PracticeWordOrderAnswer",
    created() {
        const mapText = (text, isRest = false) => {
            if (!text) {
                return []
            }

            if (typeof text === "string") {
                text = text.split("/")
            }

            return text.map((word, index) => ({
                index,
                order: index + 1,
                uuid: v4(),
                isRest,
                text: word
            }))
        }
        if (this.viewable && this.question.statements.find(s => s.shuffled_answers)) {
            return
        } else if (this.interactive) {
            this.question.statements = this.question.statements.map(statement => {
                let shuffled_answers = mapText(statement?.answers[0]?.text).filter(answer => answer.text)

                if (statement.resultStatement) {
                    shuffled_answers = shuffled_answers
                        .map(answer => ({
                            ...answer,
                            moved: statement.resultStatement && statement.resultStatement.includes(answer.text)
                        }))
                        .sort((x, y) => {
                            return x.moved === y.moved ? 0 : x.moved ? -1 : 1
                        })

                    for (const [index, text] of statement.resultStatement.entries()) {
                        shuffled_answers[index].text = text
                    }
                }

                return {
                    ...statement,
                    shuffled_answers
                }
            })

            if (this.question.isStudentView) {
                for (const statement of this.question.statements) {
                    statement.shuffled_answers = shuffle(statement.shuffled_answers)
                }
                this.$forceUpdate()
            }
        } else if (!this.interactive) {
            this.question.statements = this.question.statements.map(statement => {
                const resultStatement = this.results.find(result => result.quiz_statement_id === statement.id)

                const answered = mapText(resultStatement?.text_answer)

                let answers = []
                let shuffled_answers = [...answered]

                if (this.typeShow() === "full_information" || this.typeShow() === "only_correct_or_not") {
                    shuffled_answers.push(...mapText(resultStatement?.additional?.rest_words, true))

                    if (this.typeShow() === "full_information") {
                        const correct = mapText(statement?.answers[0]?.text)
                        const falseAnswers = mapText(statement?.answers[1]?.text, true)

                        answers = [...correct, ...falseAnswers]
                    }
                }

                shuffled_answers = shuffled_answers.filter(answer => answer.text)
                answers = answers.filter(answer => answer.text)

                if (
                    !shuffled_answers.length &&
                    statement?.answers?.length &&
                    (this.typeShow() === "full_information" || this.typeShow() === "only_correct_or_not") &&
                    this.isSkipped()
                ) {
                    shuffled_answers = mapText(
                        (statement?.answers[0].text + "/" + (statement?.answers[1]?.text ?? ""))
                            .split("/")
                            .filter(item => !!item)
                            .sort(() => 0.5 - Math.random()) //shuffle
                            .join("/")
                    )

                    statement.is_force = true
                }

                return {
                    ...statement,
                    shuffled_answers,
                    answers
                }
            })
        }
    }
}
</script>

<style scoped lang="sass">

</style>
