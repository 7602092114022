<template>
    <div class="shadow-container">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "ShadowContainer"
}
</script>

<style lang="sass">
.shadow-container
    border-radius: 8px
    padding: 20px
    box-shadow: 0 4px 10px rgba(128, 158, 191, 0.15)
    background: #FFFFFF
    transition: .2s
    &.border-red
        border: 1px solid #DD4141
    @media (max-width: 480px)
        padding: 20px 10px
    &:empty
        display: none
    &:not(&:last-child)
        margin-bottom: 20px

@keyframes skeleton-loading
    0%
        background-color: #fcfcfd
    100%
        background-color: rgb(236 236 236)

.loader-container
    animation: skeleton-loading 1s linear infinite alternate
    box-shadow: none
    margin-bottom: 20px
    min-height: 500px
    width: 100%
    display: block
    &:empty
        display: block
</style>
