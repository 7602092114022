<template>
    <div :style="questionStyle()" class="practice-question practice-question-word-from-answer">
        <PracticeQuestionInfo
            v-bind="widget()"
            :expert-view="expertView"
            :number="questionNumber"
            :name="question.name"
            :question="question"
            :quiz="quiz"
        />

        <PracticeQuestionDefaultOptions
            v-if="interactive"
            :attempt="attempt"
            :quiz="quiz"
            :question="question"
            @next="$emit('next')"
        />
        <div v-else class="mb-10"></div>

        <PracticeQuestionDescription class="pt-10 pb-10" :description="question.description" v-if="false" />
        <div v-else class="pb-10"></div>

        <FilesNewView v-if="question.files && question.files.length" class="mt-20 mb-20" :files="question.files" />

        <default-divider v-if="showDivider()" class="mb-10" color="rgba(243, 243, 243, 1)"></default-divider>

        <blue-alert v-if="interactive" class="mb-20">
            {{ t("course.practice.chars_description") }}
        </blue-alert>

        <div
            class="practice-question__answers"
            :class="{
                'practice-question__answers_disabled': disabledByTimer(),
                'pointer-events-none': !interactive || readonly
            }"
            v-if="isShowAnswers()"
        >
            <PracticeWordFromCharsDesktop v-on="$listeners" v-bind="$props" v-if="$screenWidth > 640" />
            <PracticeWordFromCharsMobile v-on="$listeners" v-bind="$props" v-else />
        </div>

        <PracticeCorrectStatusAlert
            v-if="!interactive"
            :is-correct="isCorrect"
            :is-incorrect="isIncorrect"
            :is-skipped="isSkipped"
            :is-not-started="isNotStarted"
        />

        <PracticeQuestionComment v-if="isDisplayComment" :question="question" />

        <PracticeActionsContainer :question="question" :quiz="quiz" v-if="interactive" v-on="$listeners" />
    </div>
</template>

<script>
import PracticeCorrectStatusAlert
    from "@components/Practice/PracticeQuestions/components/PracticeCorrectStatusAlert.vue"
import BlueAlert from "../../Alerts/BlueAlert"
import DefaultDivider from "../../Typography/DefaultDivider"
import PracticeMixin from "./_mixin"
import PracticeActionsContainer from "./components/PracticeActionsContainer.vue"
import PracticeQuestionDefaultOptions from "./components/PracticeQuestionDefaultOptions"
import FilesNewView from "../../Files/FilesNewView"
import { v4 } from "uuid"
import PracticeQuestionInfo from "./components/PracticeQuestionInfo"
import PracticeWordFromCharsMobile
    from "@components/Practice/PracticeQuestions/components/PracticeWordFromCharsMobile.vue"
import PracticeWordFromCharsDesktop
    from "@components/Practice/PracticeQuestions/components/PracticeWordFromCharsDesktop.vue"

export default {
    components: {
        PracticeCorrectStatusAlert,
        PracticeWordFromCharsDesktop,
        PracticeWordFromCharsMobile,
        BlueAlert,
        DefaultDivider,
        PracticeQuestionInfo,
        PracticeActionsContainer,
        FilesNewView,
        PracticeQuestionDefaultOptions
    },
    mixins: [PracticeMixin],
    name: "PracticeWordFromChars",
    data() {
        return {
            chars: [],
            noEmptyChars: [],
            entered: null,
            dragged: null
        }
    },
    created() {
        if (this.interactive) {
            const map = ({ empty }, index) => ({
                index,
                order: index,
                id: this.uuid(),
                char: empty ? "" : this.answer.text[index]
            })

            let empty = new Array(this.answer.text.length).fill({ empty: true }).map(map)
            const filled = new Array(this.answer.text.length).fill({}).map(map)

            if (this.viewable) {
                const result = this.isAnswered(this.answer)

                if (result && result.text_answer) {
                    if (this.$screenWidth <= 640) {
                        this.question.text_answer = result.text_answer
                    }

                    empty = new Array(result.text_answer.length).fill({}).map((_, index) => {
                        const char = result.text_answer[index]

                        const fill = filled.find(fill => fill.char === char)
                        if (fill) {
                            if (this.$screenWidth > 640) {
                                filled[filled.indexOf(fill)].char = ""
                            } else {
                                filled.splice(filled.indexOf(fill), 1)
                            }
                        }

                        return {
                            index,
                            order: index,
                            id: this.uuid(),
                            char
                        }
                    })
                }
            }

            this.chars = [...empty, ...filled]

            this.noEmptyChars = [...filled]

            this.$nextTick(() => {
                this.order()
                this.index()
            })
        } else {
            const result = this.isAnswered(this.answer)
            if (result && result.text_answer) {
                this.chars = new Array(result.text_answer.length).fill({}).map((_, index) => ({
                    index,
                    order: index,
                    id: this.uuid(),
                    char: result.text_answer[index]
                }))
            }
        }
    },
    computed: {
        answer() {
            return this.question.answers[0]
        }
    },
    methods: {
        showDivider() {
            return (
                this.isShowAnswers() &&
                this.question.description &&
                this.question.description !== `<p style="text-align: left"></p>`
            )
        },
        removeChar() {
            this.question.text_answer = this.question.text_answer.substring(0, this.question.text_answer.length - 1)

            this.$set(this.question, "is_allowed", this.question.text_answer.length)

            this.$emit("callout")

            if (this.question.saved && this.question.saved.length) {
                const char = this.question.saved[this.question.saved.length - 1]
                if (char) {
                    this.noEmptyChars.splice(char.index, 0, char)
                    this.question.saved.splice(this.question.saved.length - 1, 1)
                }

                this.$nextTick(() => {
                    this.order()
                    this.index()
                    this.$emit("callout")
                })
            }

            this.$forceUpdate()
        },
        addChar(ch) {
            if (!this.question.text_answer) {
                this.question.text_answer = ""
            }

            if (!this.question.saved) {
                this.question.saved = []
            }

            this.question.saved.push(ch)
            this.question.text_answer += ch.char
            this.noEmptyChars.splice(ch.index, 1)

            this.$set(this.question, "is_allowed", true)

            this.$nextTick(() => {
                this.order()
                this.index()
                this.$emit("callout")
            })
        },
        handleChar(ch) {
            const char = this.chars.find(char => char.id === ch.id)

            const isFilled = this.chars.indexOf(char) < this.chars.length / 2

            if (ch.char) {
                const empty = this.chars.find(
                    c => !c.char && (!isFilled ? c.index < this.chars.length / 2 : c.index >= this.chars.length / 2)
                )

                if (empty) {
                    const index = this.chars.indexOf(empty)
                    const _items = Object.assign([], this.chars)
                    _items[index] = char
                    _items[char.index] = empty
                    this.chars = _items

                    this.order()
                    this.index()
                    this.$forceUpdate()
                }
            }

            this.$nextTick(() => {
                this.$emit("callout")
            })
        },
        handleDragStart(item) {
            this.dragged = item.clone.dataset.id
        },
        handleDragEnd() {
            this.futureItem = this.chars[this.futureIndex]
            this.movingItem = this.chars[this.movingIndex]
            const _items = Object.assign([], this.chars)
            _items[this.futureIndex] = this.movingItem
            _items[this.movingIndex] = this.futureItem

            this.chars = _items
            this.dragged = null
            this.entered = null
            this.index()
            this.$emit("callout")
        },

        handleMove(e) {
            const { index, futureIndex } = e.draggedContext
            this.movingIndex = index
            this.futureIndex = futureIndex
            return false
        },
        isCorrectChar(index) {
            const result = this.isAnswered(this.answer)
            if (result) {
                return !result?.additional?.words_diff[index]
            }
            return true
        },
        uuid() {
            return v4()
        },
        index() {
            this.chars = this.chars.map((_, index) => ({ ..._, index }))
            this.noEmptyChars = this.noEmptyChars.map((_, index) => ({ ..._, index }))
        },
        order() {
            this.chars = this.chars.map((_, index) => ({ ..._, order: index }))
            this.noEmptyChars = this.noEmptyChars.map((_, index) => ({ ..._, order: index }))
        }
    },
    watch: {
        chars: {
            deep: true,
            handler() {
                if (!this.interactive || this.$screenWidth < 640) {
                    return
                }
                this.$nextTick(() => {
                    this.question.text_answer = ""
                    const chars = this.chars.slice(0, this.chars.length / 2)

                    for (let i = 0; i < chars.length; i++) {
                        this.question.text_answer += chars[i].char || " "
                    }

                    this.$set(
                        this.question,
                        "is_allowed",
                        this.question.text_answer.split("").filter(c => c !== " ").length
                    )
                })
            }
        }
    }
}
</script>

<style lang="sass">
.practice-question-word-from-answer
    .shadow
        min-width: 50px
        width: 50px
        height: 50px
        background: #FAFAFA
        box-shadow: inset 0px 2px 5px rgba(128, 158, 191, 0.25)
        border-radius: 8px
        color: #52565C
        font-size: 16px
        line-height: 24px
        font-weight: 600
        display: flex
        align-items: center
        justify-content: center
        cursor: pointer
        text-transform: uppercase
        border: 1px solid transparent
        &.spacer
            background: #f6faff !important
            color: #626779 !important
            font-family: 'Ubuntu', serif
            font-size: .6em
            font-weight: 500
        &:not(&:empty):not(&.spacer)
            z-index: 2
            background: #FBFCFF !important
            box-shadow: 0px 2px 5px rgba(128, 158, 191, 0.25) !important
        &:empty
            &:hover
                border: 1px dashed #3965FF !important
                box-shadow: 0px 2px 5px rgba(128, 158, 191, 0.25) !important
        &:hover
            color: #3965FF !important
            background: #FBFCFF !important

        &.active
            border-color: #3965FF !important
        &.clone
            position: absolute !important
        &.hovered
            border: 1px solid #3965FF !important

        &.colored
            &.red
                background: #FFF6F6 !important
                color: #DD4141 !important
            &.green
                color: #15A06E !important
</style>
