<template>
    <div class="course__lesson_files mt-20">
        <h4 v-if="item.title" class="course__content_summary__title bold">
            {{ item.title }}
        </h4>
        <template v-if="item.files.length">
            <audio-player
                v-if="!getAudioFile(item.files).is_converted"
                :is-public="true"
                :item="getAudioFile(item.files)"
                :infoUnitId="item.id"
            />

            <!--            <hls-audio-player
                :audio-url="getAudioFile(item.files).url"
                :item="getAudioFile(item.files)"
                v-else-if="!isIOS()"
            />-->

            <videojs-player
                v-else
                is-audio-hls
                :video-link="getAudioFile(item.files).url"
                :video-type="{ name: 'application/x-mpegURL', isHls: true }"
            />
        </template>
    </div>
</template>

<script>
import VideojsPlayer from "@components/Players/VideojsPlayer.vue"

export default {
    name: "CoursePublicUnitsAudio",
    components: {
        VideojsPlayer,
        AudioPlayer: () => import("@components/Players/AudioPlayer")
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    methods: {
        isIOS() {
            return (
                ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(
                    navigator.platform
                ) ||
                // iPad on iOS 13 detection
                (navigator.userAgent.includes("Mac") && "ontouchend" in document)
            )
        },
        getAudioFile(files) {
            return files.find(f => f.type_id === 5)
        }
    }
}
</script>
