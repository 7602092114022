<template>
    <div class="audio-player">
        <audio ref="audio" class="audio-element hidden" controls :src="value.url || this.value.audioUrl"></audio>
        <div :class="{ 'audio-container_disabled': !canPlay }" class="audio-container">
            <button @click="handleClick" class="audio-button">
                <PlayIcon v-if="[AUDIO_STATES.UNSTARTED, AUDIO_STATES.PAUSED].includes(state)" />
                <PauseIcon v-else-if="state === AUDIO_STATES.PLAYING" />
            </button>
            <div class="audio-time-container">
                <div class="range-container">
                    <input
                        :style="{ backgroundSize: `${(current * 100) / duration}% 100%` }"
                        :value="(current * 100) / duration"
                        @input="updateCurrentTime"
                        min="0"
                        max="100"
                        step="1"
                        type="range"
                    />
                </div>
                <span class="audio-time"> {{ formatTime(current) }} / {{ formatTime(duration) }} </span>
            </div>

            <ExpertSimpleMenu>
                <p @click.stop="setRate()" class="simple-menu-item">
                    <SpeedIcon />
                    Playback rate: {{ rate }}x
                </p>
                <a class="simple-menu-item" download="music" target="_blank" :href="value.url || this.value.audioUrl">
                    <Download2Icon />
                    Download audio
                </a>
                <p @click="$emit('delete')" v-if="withRemove" class="simple-menu-item simple-menu-item_red">
                    <DeleteIcon />
                    Delete
                </p>
            </ExpertSimpleMenu>
        </div>

        <FormMsg status="error" :msg-text="msg" v-if="msg" />
    </div>
</template>

<script>
import FormMsg from "@components/Forms/FormMsg.vue"
import ExpertSimpleMenu from "@expert-components/ExpertMenu/ExpertSimpleMenu.vue"
import DeleteIcon from "@icons/DeleteIcon.vue"
import Download2Icon from "@icons/Download2Icon.vue"
import PauseIcon from "@icons/PauseIcon.vue"
import PlayIcon from "@icons/PlayIcon.vue"
import SpeedIcon from "@icons/SpeedIcon.vue"

const AUDIO_STATES = {
    UNSTARTED: -1,
    ENDED: 0,
    PLAYING: 1,
    PAUSED: 2,
    BUFFERING: 3
}
export default {
    components: { SpeedIcon, DeleteIcon, Download2Icon, ExpertSimpleMenu, FormMsg, PlayIcon, PauseIcon },
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        withRemove: {
            type: Boolean,
            default: false
        },
        settings: {
            type: Object,
            default: () => ({})
        }
    },
    name: "RecordingAudioPlayer",
    data() {
        return {
            AUDIO_STATES,
            state: AUDIO_STATES.UNSTARTED,
            el: null,
            duration: null,
            current: null,
            msg: "",
            rate: 1,
            canPlay: false
        }
    },
    mounted() {
        this.el = this.$refs.audio
        this.listenEvents()
    },
    methods: {
        listenEvents() {
            this.el.addEventListener("playing", () => {
                this.state = AUDIO_STATES.PLAYING
            })
            this.el.addEventListener("durationchange", () => {
                this.duration = Math.round(this.el.duration * 1000)
            })
            this.el.addEventListener("loadedmetadata", () => {
                this.duration = Math.round(this.el.duration * 1000)
            })
            this.el.addEventListener("ratechange", () => {
                this.rate = this.el.playbackRate
            })
            this.el.addEventListener("timeupdate", () => {
                this.current = Math.round(this.el.currentTime * 1000)
            })
            this.el.addEventListener("pause", () => {
                this.state = AUDIO_STATES.PAUSED
            })
            this.el.addEventListener("canplay", () => {
                this.canPlay = true
            })
            this.el.addEventListener("stalled", () => {
                this.msg = "Failed to fetch data, but trying."
            })
            this.el.addEventListener("error", () => {
                this.msg = "Failed to fetch data."
            })
        },
        updateCurrentTime(event) {
            this.el.currentTime = (this.duration * event.target.value) / 100000
        },
        formatTime(time) {
            if (!time) {
                return `0:00`
            }

            return this.convertDuration(time)
        },
        setRate() {
            if (this.el.playbackRate === 1) {
                this.el.playbackRate = 1.25
            } else if (this.el.playbackRate === 1.25) {
                this.el.playbackRate = 1.5
            } else if (this.el.playbackRate === 1.5) {
                this.el.playbackRate = 2
            } else if (this.el.playbackRate === 2) {
                this.el.playbackRate = 0.5
            } else {
                this.el.playbackRate = 1
            }
        },
        convertDuration(milliseconds) {
            const seconds = Math.floor((milliseconds / 1000) % 60)
            const minutes = Math.floor((milliseconds / 1000 / 60) % 60)
            const hours = Math.floor(milliseconds / 1000 / 60 / 60)

            let formattedTime

            if (milliseconds < 60000) {
                formattedTime = [minutes.toString(), seconds.toString().padStart(2, "0")].join(":")
            } else {
                if (hours === 0) {
                    formattedTime = [minutes.toString(), seconds.toString().padStart(2, "0")].join(":")
                } else {
                    formattedTime = [
                        hours.toString(),
                        minutes.toString().padStart(2, "0"),
                        seconds.toString().padStart(2, "0")
                    ].join(":")
                }
            }

            return formattedTime
        },
        handleClick() {
            if ([AUDIO_STATES.UNSTARTED, AUDIO_STATES.PAUSED].includes(this.state)) {
                this.el.play()
                this.el.volume = 1
                this.el.muted = false
            } else if (this.state === AUDIO_STATES.PLAYING) {
                this.el.pause()
            }
        }
    }
}
</script>

<style scoped lang="sass">
.audio-time-container
    display: flex
    flex-direction: row-reverse
    grid-gap: 12px
.audio-player
    min-height: 50px
.audio-element
    &.hidden
        visibility: hidden
        position: absolute
        pointer-events: none
        z-index: -1
.audio-container
    width: 100%
    max-width: 290px
    background: #FFFFFF
    border: 1px solid #E0E0EC
    border-radius: 10px
    padding: 10px
    padding-right: 15px
    display: grid
    grid-template-columns: 32px 1fr 20px
    column-gap: 12px
    position: relative
    align-items: center
    box-shadow: 0 2px 5px rgba(128, 158, 191, 0.25)
    z-index: 1
    @media (max-width: 420px)
        max-width: 100%
        grid-template-columns: 32px 1fr 20px
    &::v-deep
        .dots-menu-icon
            width: 20px
    &_disabled
        //box-shadow: none
        //opacity: .4
.range-container
    width: 100%
    height: 24px
    transition: .2s
    display: flex
    align-items: center
    input[type="range"]
        cursor: pointer
        -webkit-appearance: none
        width: 100px
        height: 4px
        border-radius: 5px
        background: rgba(231, 231, 231, 0.6) linear-gradient(#B5C1D2, #B5C1D2) no-repeat
        transition: height .2s
        @media (max-width: 420px)
            width: 100%
        &::-webkit-slider-runnable-track, &::-moz-range-track
            -webkit-appearance: none
            box-shadow: none
            border: none
            background: transparent
        &::-webkit-slider-thumb
            -webkit-appearance: none
            height: 12px
            width: 12px
            transform: scale(0)
            margin-left: -2px
            border-radius: 50%
            background: #3965FF
            box-shadow: 0 0 2px 0 #555
            transition: .2s
            background: rgba(255, 255, 255, 0.6) linear-gradient(#FFF, #FFF) no-repeat
            &::-webkit-slider-thumb
                background: #FFF
                margin-left: 0 !important
    &:hover
        input
            &::-webkit-slider-thumb
                transform: scale(1)
.audio-time
    display: flex
    align-items: center
    width: 70px
    white-space: nowrap
    font-size: 13px
    font-family: Inter, sans-serif
.audio-button
    height: 32px
    width: 32px
    background-color: #B5C1D2
    border-radius: 50%
    display: flex
    align-items: center
    justify-content: center
    svg
        fill: #fff
        width: 25px
        height: 25px
</style>
