<template>
    <div class="create-popup_p-for-nav create-popup create-course fixed-position">
        <div class="wrapper">
            <div class="filter-overlay"></div>
            <div class="wrapper">
                <div
                    class="create-popup__slides create-popup__slides_just-left create-popup__slides_p-for-check create-course__slider"
                >
                    <div class="delete-lesson" style="transform: translate(-20%, -50%)">
                        <p class="delete-lesson__title">{{ modalTitle }}</p>

                        <div class="delete-lesson__lessons-wrapper">
                            <div
                                v-if="itemTitle"
                                id="deleteModuleModal"
                                style="overflow: hidden"
                                class="delete-lesson__lessons-container"
                            >
                                <div class="delete-lesson__info">
                                    <div class="delete-lesson__info-content">
                                        <p class="delete-lesson__video-title">{{ itemTitle }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="delete-lesson__buttons">
                            <button
                                @click="closeModal"
                                class="delete-lesson__btn btn2 btn2_clear btn2_w-100"
                                type="button"
                            >
                                {{ t("expert.no_cancel") }}
                            </button>

                            <button
                                @click="deleteItem(itemId)"
                                class="delete-lesson__btn btn2 btn2_danger btn2_w-100"
                                type="button"
                            >
                                {{ t("expert.yes_delete") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DeleteModal",
    props: {
        data: {
            type: Object,
            default: () => ({})
        },
        itemTitle: {
            type: String,
            default: ""
        },
        itemId: {
            type: Number,
            String
        },
        parent: {
            type: String
        },
        modalTitle: {
            type: String
        }
    },
    methods: {
        closeModal() {
            this.$emit("closeModal")
        },
        deleteItem(id) {
            this.$emit("deleteItem", id)
        }
    },
    created() {}
}
</script>

