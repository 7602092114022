<template>
    <div :style="questionStyle()" class="practice-question">
        <PracticeQuestionInfo
            v-bind="widget()"
            :expert-view="expertView"
            :number="questionNumber"
            :name="question.name"
            :question="question"
            :quiz="quiz"
        />

        <PracticeQuestionDefaultOptions
            v-if="interactive"
            :attempt="attempt"
            :quiz="quiz"
            :question="question"
            @next="$emit('next')"
        />

        <div
            v-if="isShowAnswers()"
            class="practice-question__answers"
            :class="{ 'practice-question__answers_disabled': disabledByTimer() }"
        >
            <custom-collapse v-if="isAllAnswersChoice()" :is-open="getCases().length">
                <div v-if="isAllAnswersChoice() && interactive" class="practice-question__all-answers mb-20">
                    <transition-group name="list-complete" tag="p">
                        <span class="list-complete-item" :key="answer.id" v-for="answer in getCases()">
                            {{ answer.text }}
                        </span>
                    </transition-group>
                </div>
            </custom-collapse>
            <div
                class="practice-question__statement-container paste-word-container shadow-container"
                :key="index"
                :class="getStatementClasses(statement)"
                v-for="(statement, index) in question.statements"
            >
                <flex-container align="center">
                    <img class="rounded" height="40" v-if="statement.image" :src="statement.image.url" alt="" />
                    {{ statement.text }}
                </flex-container>
                <flex-container align="center">
                    <DefaultInput
                        v-if="!isDropdown()"
                        pointer
                        :value="getAnswerValue(statement)"
                        @input="setAnswerValue(statement, $event)"
                        :placeholder="t('quiz.input_answer').capitalize()"
                    />
                    <DefaultDropdown
                        v-else
                        :with-delete="(isAllAnswersChoice() || isWithMultiple(statement)) && interactive"
                        :with-multiple="isWithMultiple(statement)"
                        @delete="deleteDropdownValue(statement)"
                        @update="setDropdownValue(statement, $event)"
                        :active-case="getDropdownValue(statement)"
                        :cases="getCases(statement)"
                        :placeholder="t('quiz.select_answer').capitalize()"
                        style-modifier="pointer"
                    />
                </flex-container>
            </div>

            <template v-if="typeShow() === 'full_information' && !interactive">
                <p class="practice-question-title mt-20 mb-15">
                    {{ t("quiz.correct_answer") }}
                </p>

                <div
                    class="practice-question__statement-container pointer-events-none green paste-word-container shadow-container"
                    :key="`${index}-correct`"
                    v-for="(statement, index) in question.statements"
                >
                    <flex-container align="center">
                        <img class="rounded" height="40" v-if="statement.image" :src="statement.image.url" alt="" />
                        {{ statement.text }}
                    </flex-container>
                    <flex-container align="center">
                        <DefaultInput
                            v-if="!isDropdown()"
                            pointer
                            :value="getAnswerValue(statement, true)"
                            :placeholder="t('quiz.input_answer').capitalize()"
                        />
                        <DefaultDropdown
                            v-else
                            :active-case="getDropdownValue(statement, true)"
                            :cases="[]"
                            :placeholder="t('quiz.select_answer').capitalize()"
                            style-modifier="pointer"
                        />
                    </flex-container>
                </div>
            </template>
        </div>

        <PracticeCorrectStatusAlert
            v-if="!interactive"
            :is-correct="isCorrect"
            :is-incorrect="isIncorrect"
            :is-skipped="isSkipped"
            :is-not-started="isNotStarted"
        />

        <PracticeQuestionComment v-if="isDisplayComment" :question="question" />

        <PracticeActionsContainer
            class="mt-25"
            v-if="interactive && !didUpdateAction"
            :question="question"
            :quiz="quiz"
            v-on="$listeners"
        />
    </div>
</template>

<script>
import FlexContainer from "@components/Containers/FlexContainer.vue"
import CustomCollapse from "@components/Collapse/CustomCollapse.vue"
import DefaultDropdown from "@components/Forms/DefaultDropdown.vue"
import DefaultInput from "@components/Forms/DefaultInput.vue"
import PracticeMixin from "@components/Practice/PracticeQuestions/_mixin"
import { QUESTION_TYPES } from "@components/Practice/PracticeQuestions/_types"
import PracticeActionsContainer from "@components/Practice/PracticeQuestions/components/PracticeActionsContainer.vue"
import PracticeCorrectStatusAlert
    from "@components/Practice/PracticeQuestions/components/PracticeCorrectStatusAlert.vue"
import PracticeQuestionDefaultOptions
    from "@components/Practice/PracticeQuestions/components/PracticeQuestionDefaultOptions.vue"
import PracticeQuestionInfo from "@components/Practice/PracticeQuestions/components/PracticeQuestionInfo.vue"
import ImageEnlargable from "@mixins/image-enlargable"
import shuffle from "lodash.shuffle"

export default {
    components: {
        PracticeCorrectStatusAlert,
        CustomCollapse,
        DefaultDropdown,
        PracticeActionsContainer,
        DefaultInput,
        FlexContainer,
        PracticeQuestionInfo,
        PracticeQuestionDefaultOptions
    },
    props: {
        question: {
            type: Object,
            default: () => {}
        }
    },
    name: "PracticePasteWordAnswer",
    mixins: [PracticeMixin, ImageEnlargable],
    mounted() {
        this.initImages()
    },
    methods: {
        isDropdown() {
            return (
                this.question.type.slug === QUESTION_TYPES.CHOOSE_FROM_LIST ||
                this.question.type.slug === QUESTION_TYPES.MULTI_CHOOSE_FROM_LIST
            )
        },
        isWithMultiple() {
            return this.question.type.slug === QUESTION_TYPES.MULTI_CHOOSE_FROM_LIST
        },
        getStatementClasses(statement) {
            const classes = []

            if (!this.interactive) {
                const { id } = statement.answer_groups[0]

                const results = this.results.filter(r => r.quiz_answer_group_id === id)

                if (this.typeShow() === "full_information" || this.typeShow() === "only_correct_or_not") {
                    if (results && results.length && !this.isSkipped()) {
                        if (results[0].attempt_statement_result.is_correct) {
                            classes.push("green")
                        } else {
                            classes.push("red")
                        }
                    }
                }
            }

            if (!this.interactive || this.readonly) {
                classes.push("pointer-events-none")
            }

            return classes
        },
        getDropdownValue(statement, correct) {
            if (correct) {
                return statement.answers
                    .filter(a => a.is_correct)
                    .map(a => a.text)
                    .join(` / `)
            }

            if (this.interactive) {
                if (!statement.selected_answers) {
                    return ""
                }
                return statement.selected_answers.map(a => a.text).join(", ")
            }

            const { id } = statement.answer_groups[0]

            return this.results
                .filter(r => r.quiz_answer_group_id === id)
                .map(r => r?.answer?.text)
                .join(", ")
        },
        deleteDropdownValue(statement) {
            statement.selected_answers = []
            this.forceUpdateAction()
            this.$forceUpdate()
        },
        setDropdownValue(statement, a) {
            if (!statement.selected_answers) {
                statement.selected_answers = []
            }

            if (this.isWithMultiple(statement)) {
                const selected = statement.selected_answers.find(answer => answer.id === a.id)

                if (selected) {
                    statement.selected_answers.splice(statement.selected_answers.indexOf(selected), 1)
                } else {
                    statement.selected_answers.push(a)
                }
            } else {
                statement.selected_answers = [a]
            }

            this.$emit("callout")
            this.forceUpdateAction()
            this.$forceUpdate()
        },
        getCases(statement) {
            const mapAnswers = answers => {
                if (this?.quiz?.options?.is_random_answers || this?.quiz?.settings?.options?.is_random_answers) {
                    return shuffle(answers.filter(a => a.text))
                }

                return answers && answers.filter(a => a.text)
            }

            if (this.isAllAnswersChoice()) {
                const activeAnswers = []
                const answers = []

                for (const statement of this.question.statements) {
                    if (statement.selected_answers) {
                        for (const answer of statement.selected_answers) {
                            activeAnswers.push(answer.id)
                        }
                    }
                }

                for (const statement of this.question.statements) {
                    answers.push(...statement.answers.filter(answer => !activeAnswers.includes(answer.id)))
                }

                return mapAnswers(answers)
            } else {
                return mapAnswers(statement.answers)
            }
        },
        getAnswerValue(statement, correct) {
            if (correct) {
                return statement.answers
                    .filter(a => a.is_correct)
                    .map(a => a.text)
                    .join(` / `)
            }

            if (this.interactive) {
                return statement.answer_groups[0].text_answer
            }
            const { id } = statement.answer_groups[0]

            return this.results.find(r => r.quiz_answer_group_id === id)?.text_answer || ""
        },
        setAnswerValue(statement, val) {
            statement.answer_groups[0].text_answer = val
            this.$emit("callout")
            this.forceUpdateAction()
            this.$forceUpdate()
        }
    }
}
</script>

<style lang="sass" scoped>
.paste-word-container
    display: flex
    grid-gap: 40px
    img
        margin-right: 8px
    .flex-container
        font-size: 13px
        font-weight: 600
        color: #151515
        line-height: 150%
    & > .flex-container:first-child
        max-width: 320px
    & > .flex-container:last-child
        width: 50%
    @media (max-width: 780px)
        flex-direction: column
        grid-gap: 20px
        & > .flex-container
            max-width: none !important
            width: 100% !important
    @media (max-width: 480px)
        grid-gap: 10px
</style>
