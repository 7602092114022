<template>
    <div class="order-statement">
        <default-description
            v-if="statement.text"
            class="mb-5"
            :weight="600"
            :line-height="18"
            :size="14"
            text-color="#323030"
        >
            <span v-if="question.statements.length > 1" class="number"> {{ number }}. </span>{{ statement.text }}
        </default-description>

        <flex-container>
            <span v-if="!statement.text && question.statements.length > 1" class="number mt-10 mr-14">
                {{ number }}.
            </span>
            <slot></slot>

            <draggable
                :class="{ 'pointer-events-none': readonly || !interactive }"
                v-model="statement[answersKey]"
                :move="handleMove"
                :handle="isAndroid() ? '.move-handler' : null"
                @end="
                    $emit('callout')
                    drag = false
                "
                @start="drag = true"
                v-bind="dragOptions"
            >
                <transition-group
                    tag="div"
                    class="answers-container"
                    type="transition"
                    :name="drag ? 'move-list' : null"
                >
                    <div
                        v-for="answer in statement[answersKey]"
                        @click="handleClick(answer, $event)"
                        @contextmenu.prevent="handleClick(answer, $event)"
                        :key="answer.uuid || answer.id"
                        :class="{
                            correct: isAnswerCorrect(answer),
                            incorrect: !isAnswerCorrect(answer),
                            moved: answer.moved,
                            android: isAndroid(),
                            rest: answer.isRest,
                            colored:
                                isAnswerCorrect(answer) !== undefined &&
                                !statement.is_force &&
                                !interactive &&
                                typeShow !== 'simple_answer' &&
                                typeShow !== 'only_correct_or_not_question'
                        }"
                        class="order-answer"
                    >
                        <transition name="fade-up">
                            <button v-if="answer.moved && isAndroid()" class="move-handler">
                                <Move1Icon />
                            </button>
                        </transition>
                        <span>
                            {{ answer.text }}
                        </span>
                    </div>
                </transition-group>
            </draggable>
        </flex-container>
    </div>
</template>

<script>
import FlexContainer from "@components/Containers/FlexContainer.vue"
import DefaultDescription from "@components/Typography/DefaultDescription.vue"
import Move1Icon from "@icons/Move1Icon.vue"
import draggable from "vuedraggable"

export default {
    components: { Move1Icon, FlexContainer, draggable, DefaultDescription },
    props: {
        statement: {
            type: Object,
            default: () => {}
        },
        results: {
            type: Array,
            default: () => []
        },
        readonly: {
            type: Boolean,
            default: false
        },
        viewable: {
            type: Boolean,
            default: false
        },
        interactive: {
            type: Boolean,
            default: false
        },
        question: {
            type: Object,
            default: () => {}
        },
        typeShow: {
            type: String,
            default: ""
        },
        answersKey: {
            type: String,
            default: "shuffled_answers"
        },
        expertView: {
            type: Boolean,
            default: false
        },
        number: {
            type: Number,
            default: 0
        },
        attempt: {
            type: Object,
            default: () => {}
        },
        lesson: {
            type: Object,
            default: () => {}
        }
    },
    name: "PracticeWordOrderStatement",
    data() {
        return {
            drag: false,
            dragOptions: {
                touchStartThreshold: 5,
                animation: 200,
                delay: 50,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
                forceFallback: navigator.userAgent.toLowerCase().indexOf("firefox") > -1
            }
        }
    },
    methods: {
        isAndroid() {
            return /(android)/i.test(navigator.userAgent)
        },
        handleMove(evt) {
            return !!this.statement[this.answersKey][evt.draggedContext.futureIndex].moved
        },
        handleClick(answer) {
            const answers = this.statement[this.answersKey]
            this.drag = true

            if (answer.moved) {
                answer.moved = false
            } else {
                answer.moved = true
            }

            answers.sort((x, y) => {
                return x.moved === y.moved ? 0 : x.moved ? -1 : 1
            })

            this.$emit("callout")

            this.$forceUpdate()

            setTimeout(() => {
                this.drag = true
            }, 200)
        },
        isAnswerCorrect(answer) {
            if (this.interactive) {
                return false
            }

            if (this.answersKey === "answers") {
                return true
            }

            const result = this.results.find(result => result.quiz_statement_id === this.statement.id)

            if (result) {
                return !result?.additional?.sentence_diff[answer.index]
            }
            return true
        },
        isAnswered(answer) {
            const result = this.results && this.results.find(r => r.quiz_answer_id === answer.id)

            if (this.interactive || !this.results) {
                return true
            }

            return result
        }
    }
}
</script>

<style scoped lang="sass">
.order-answer
    margin: 4px 4px
    white-space: nowrap
    padding: 10px
    font-size: 13px
    font-weight: 400
    color: #52565C
    border: 1px solid #E0E0EC
    background-color: #fff
    cursor: pointer
    border-radius: 7px
    -webkit-user-drag: none
    -khtml-user-drag: none
    -moz-user-drag: none
    -o-user-drag: none
    user-drag: none
    position: relative
    display: flex
    align-items: center

    &:hover
        background-color: rgba(224, 224, 236, 0.27)

    span
        user-select: none
        pointer-events: none
    .move-handler
        position: absolute
        left: 2px
        top: 4px
    &.moved
        color: #3965FF
        border-color: #3965FF
        -webkit-user-drag: element
        -khtml-user-drag: element
        -moz-user-drag: element
        -o-user-drag: element
        user-drag: element
        &.android
            padding-left: 25px
    &.colored
        &.correct
            color: #15A06E
            border-color: #15A06E
        &.incorrect
            color: #DD4141
            border-color: #DD4141
        &.rest
            color: #52565C
            border-color: #E0E0EC
.answers-container
    display: flex
    align-items: center
    flex-wrap: wrap
    margin-left: -8px

.move-list-move
    transition: transform 0.5s

.no-move
    transition: transform 0s
.number
    margin-right: 4px
    font-size: 14px
    line-height: 1.5
    font-weight: 400
    color: #B5C1D2
    white-space: nowrap
    &.mr-14
        margin-right: 14px
.ghost
    opacity: 0.5
    background-color: rgba(#E0E0EC, .5)

.order-statement
    &:not(&:last-child)
        margin-bottom: 20px
</style>
