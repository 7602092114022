<template>
    <div class="comments-public" :class="{ 'comments-public_reversed': direction === 'column-reverse' }">
        <portal to="modals" v-if="displayBulkDeleteModal">
            <DeleteModal
                @delete="bulkDelete()"
                @cancel="displayBulkDeleteModal = false"
                :title="t('course.delete_all')"
                :centred="true"
                :loading="bulkLoader"
            />
        </portal>
        <h3 v-if="displayTitle && langsKeys" class="comments-public__title">
            {{ t("course.course_card.comments") }}
            <span v-if="comments.length" class="text-blue">
                {{ comments.length }}
            </span>
        </h3>
        <p v-if="!auth" class="comments-public__no-auth">
            {{ t("course.comment_not_auth") }}
        </p>

        <div class="comments-public__content">
            <CommentsBulkActions
                v-if="langsKeys && allowBulkActions"
                :all-read="!unreadIds.length"
                :commentable-type="commentable_type"
                :course-id="courseId"
                :lesson-id="lessonId"
                :attempt-id="rootAttemptId"
                :isMarathon="isMarathon"
                @bulk:read="bulkRead()"
                @bulk:delete="prepareBulkDelete()"
                @bulk:restrict="bulkRestrict()"
            />

            <LoaderBlock :green-color="true" v-if="loadingGetComment || !langsKeys" />
            <div :style="{ display: 'flex', flexDirection: direction, flex: 1 }" v-else>
                <div v-if="!isOther && allowAnswer" :style="createStyle">
                    <CommentsPublicUnseen
                        v-if="parent === 'expert' && unreadIds.length"
                        @next="goNextUnseen()"
                        :unseen-comments="unreadIds"
                    />
                    <CommentsPublicCreate
                        ref="create"
                        :is-public="isPublic"
                        :key="`other-${curatorsUpdateCount}`"
                        :question-id="questionId"
                        :is-private-selection="isPrivateSelection"
                        :advanced-panel="advancedPanel"
                        :unit-id="unitId"
                        :course-id="courseId"
                        :commentable_type="commentable_type"
                        :quiz-id="quizId"
                        :langsKeys="langsKeys"
                        :allow-answer="allowAnswer"
                        :lessonId="lessonId"
                        :attempt-id="rootAttemptId"
                        :answered-comment-type="answeredCommentType"
                        :user="user"
                        :parent="parent"
                        :allowCreateComment="allowCreateComment"
                        :commentParentId="toAnswerCommentId"
                        :viewed-user="viewedUser"
                        :allow-upload-file="allowUploadFile"
                        :allow-audio-response="allowAudioResponse"
                        :allow-video-response="allowVideoResponse"
                        :allow-public-answer="allowPublicAnswer"
                        :allow-text-answer="allowTextAnswer"
                        :curators="curators"
                        @push:comment="pushComment($event)"
                        @update:commentable="$emit('update:commentable', $event)"
                    />
                </div>
                <mailing-empty-content
                    v-if="!comments.length && withEmpty"
                    icon-name="MailingListsEmptyInboxIcon"
                    :title="t('course.no_comments')"
                    summary=""
                    :display-btn="false"
                />
                <div v-if="!forseRefresh">
                    <CommentsPublicComment
                        v-for="(comment, comment_index) in displayedComments"
                        @update:comment="updateComment(comment_index, $event)"
                        @delete:comment="deleteComment(comment_index)"
                        @read:comment="readComment($event)"
                        @unread:comment="unreadComment($event)"
                        @update:commentable="$emit('update:commentable', $event)"
                        :commentable_type="commentable_type"
                        :quiz-id="quizId"
                        :is-public="isPublic"
                        :comment="comment"
                        :advanced-panel="advancedPanel"
                        :langsKeys="langsKeys"
                        :hide-actions="hideActions"
                        :lessonId="lessonId"
                        :attempt-id="rootAttemptId"
                        :commentParentId="toAnswerCommentId"
                        :viewed-user="viewedUser"
                        :user="user"
                        :level="0"
                        :unit-id="unitId"
                        :allow-public-answer="allowPublicAnswer"
                        :question-id="questionId"
                        :allowCreateComment="allowCreateComment"
                        :linked="linked"
                        :answeredCommentType="answeredCommentType"
                        :allowUploadFile="allowUploadFile"
                        :allowAudioResponse="allowAudioResponse"
                        :allow-answer="allowAnswer"
                        :allow-text-answer="allowTextAnswer"
                        :parent="parent"
                        :curators="curators"
                        :curatorPublicFace="curatorPublicFace"
                        :key="'comment-id-' + comment.id"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "~axios"
import KeyTranslator from "@mixins/keyTranslator"
import LoaderBlock from "@components/Loaders/LoaderBlock"
import { EventBus, EventsList } from "~events"
import { ExternalLoader } from "@helpers"

export default {
    name: "CommentsPublic",
    components: {
        DeleteModal: () => import("@expert-components/Modals/DeleteModal"),
        CommentsPublicUnseen: () => import("@components/Comments/CommentsPublicUnseen"),
        CommentsBulkActions: () => import("@components/Comments/CommentsBulkActions"),
        MailingEmptyContent: () => import("../../../modules/Expert/views/Marketing/Mailing/EmptyContent.vue"),
        LoaderBlock,
        CommentsPublicComment: () => import("@components/Comments/CommentsPublicComment"),
        CommentsPublicCreate: () => import("@components/Comments/CommentsPublicCreate")
    },
    mixins: [KeyTranslator],
    props: {
        advancedPanel: {
            type: Boolean,
            default: false
        },
        allowBulkActions: {
            type: Boolean,
            default: false
        },
        isPublic: {
            type: Boolean,
            default: false
        },
        isPrivateSelection: {
            type: Boolean,
            default: false
        },
        commentsEnable: {
            type: Boolean,
            required: true
        },
        allowCreateComment: {
            type: Boolean,
            default: true
        },
        linked: {
            type: Boolean,
            required: false,
            default: false
        },
        auth: {
            type: Boolean,
            required: true
        },
        noShadow: {
            type: Boolean,
            required: false
        },
        preloadedData: {
            type: [Array, Object],
            required: false,
            default: null
        },
        allowAnswer: {
            type: Boolean,
            required: false,
            default: true
        },
        stickyBottom: {
            type: Boolean,
            required: false,
            default: false
        },
        isOther: {
            type: Boolean,
            required: false,
            default: false
        },
        isMarathon: {
            type: Boolean,
            default: false
        },
        withEmpty: {
            type: Boolean,
            required: false,
            default: false
        },
        hideActions: {
            type: Boolean,
            required: false,
            default: false
        },
        lessonId: {
            type: [Number, String],
            required: true
        },
        unitId: {
            type: [Number, String],
            required: false
        },
        rootAttemptId: {
            type: [Number, String],
            required: false
        },
        questionId: {
            type: [Number, String],
            required: false
        },
        courseId: {
            type: [Number, String],
            required: true
        },
        quizId: {
            type: [Number, String],
            required: false
        },
        toAnswerCommentId: {
            type: [Number, String],
            required: false
        },
        answeredCommentType: {
            type: Boolean,
            default: false
        },
        allowUploadFile: {
            type: Boolean,
            default: false
        },
        allowAudioResponse: {
            type: Boolean,
            default: false
        },
        allowVideoResponse: {
            type: Boolean,
            default: false
        },
        displayTitle: {
            type: Boolean,
            default: true
        },
        user: {
            type: Object,
            required: false
        },
        langsProp: {
            type: Object,
            required: false
        },
        viewedUser: {
            type: Object,
            required: false
        },
        /**
         * @values "lesson", "quiz-attempt", "info-units"
         **/
        commentable_type: {
            type: String,
            default: "lesson"
        },
        allowPublicAnswer: {
            type: Boolean,
            default: true
        },
        allowTextAnswer: {
            type: Boolean,
            default: true
        },

        /**
         * @values "column", "column-reverse"
         **/
        direction: {
            type: String,
            default: "column"
        },
        /**
         * @values "expert", "student"
         **/
        parent: {
            type: String,
            default: "lesson"
        }
    },
    data() {
        return {
            //
            loadingGetComment: false,
            nextPageLink: null,
            prevPageLink: null,
            langsKeys: null,
            loadingMore: false,
            comments: [],
            query: {
                page: 1
            },
            curators: [],
            curatorPublicFace: null,
            curatorsUpdateCount: 0,
            viewedComment: null,
            forseRefresh: false,
            unreadIds: [],
            displayBulkDeleteModal: false,
            bulkLoader: false
        }
    },
    async created() {
        if (this.langsProp || (this.$store && this.langs)) {
            this.langsKeys = this.langsProp || this.langs
        } else {
            this.getLangs()
        }
        if (this.auth) {
            if (window.id_hash) this.user.id_hash = window.id_hash
            if (this.commentsEnable) {
                if (this.preloadedData) {
                    const { data, links, meta } = this.preloadedData
                    this.comments = data

                    if (links) {
                        this.nextPageLink = links.next
                        this.prevPageLink = links.prev
                    }

                    if (meta) {
                        this.query.page = meta.current_page
                    }
                } else {
                    this.loadingGetComment = true
                    await this.getComments()
                }

                this.getCurators()

                if (this.direction === "column-reverse") {
                    const { id } = this.$route.query
                    if (id) {
                        this.$nextTick(() => {
                            const el = document.querySelector(`#${id}`)
                            if (el) {
                                el.scrollIntoView()
                            } else {
                                this.scrollBottom()
                            }
                        })
                    } else {
                        this.scrollBottom()
                    }
                }
            }
        }
        EventBus.$on(EventsList.BLURED_CONTENT_CLICK, () => {
            this.displayBulkDeleteModal = false
            EventBus.$emit(EventsList.BLUR_FULL_CONTENT, false)
        })
    },
    async mounted() {
        await ExternalLoader.STYLE(["/build/css/quill.core.css", "/build/css/quill.snow.css"])
    },
    methods: {
        bulkRead() {
            axios.patch("/comments/bulk-read", this.bulkQuery()).then(() => {
                this.getComments()
                EventBus.$emit("update:unseen")
            })
        },
        prepareBulkDelete() {
            this.displayBulkDeleteModal = true
        },
        bulkDelete() {
            this.bulkLoader = true
            axios
                .delete("/comments/bulk-delete", {
                    data: this.bulkQuery()
                })
                .then(() => {
                    this.getComments()
                    EventBus.$emit("update:unseen")
                })
                .finally(() => {
                    this.bulkLoader = false
                    this.displayBulkDeleteModal = false
                })
        },
        bulkRestrict() {
            axios.patch("/comments/bulk-restrict", this.bulkQuery()).then(() => {
                this.getComments()
                EventBus.$emit("update:unseen")
            })
        },
        bulkQuery() {
            const query = {
                commentable_type: this.commentable_type
            }
            if (this.commentable_type === "quiz-attempt") {
                query.quiz_question_id = this.questionId
                query.commentable_id = this.rootAttemptId
            } else {
                query.commentable_id = this.unitId
            }

            return query
        },
        scrollBottom() {
            this.$nextTick(() => {
                if (this.unreadIds.length) {
                    this.goNextUnseen()
                } else {
                    window.scrollTo({
                        top: document.body.scrollHeight,
                        behavior: "smooth"
                    })
                }
            })
        },
        goNextUnseen() {
            if (!this.viewedComment) {
                this.viewedComment = this.unreadIds[0]
            } else if (this.unreadIds[this.unreadIds.indexOf(this.viewedComment) + 1]) {
                this.viewedComment = this.unreadIds[this.unreadIds.indexOf(this.viewedComment) + 1]
            } else {
                this.viewedComment = this.unreadIds[0]
            }
            this.scrollIntoViewed()
        },
        scrollIntoViewed() {
            const element = document.querySelector(`#commentId${this.viewedComment}`)
            if (element) {
                const intersectionObserver = new IntersectionObserver(entries => {
                    let [entry] = entries
                    if (entry.isIntersecting) {
                        setTimeout(() => {
                            document.querySelectorAll(".visited").forEach(visited => {
                                visited.classList.remove("visited")
                            })
                            entry.target.classList.add("visited")
                            setTimeout(() => {
                                entry.target.classList.remove("visited")
                            }, 2500)
                            intersectionObserver.disconnect()
                        }, 200)
                    }
                })
                intersectionObserver.observe(element)
                element.scrollIntoView({ behavior: "smooth" })
            }
        },
        deleteComment(index) {
            this.displayedComments.splice(index, 1)
        },
        readComment(id) {
            if (this.unreadIds.includes(id)) {
                this.unreadIds.splice(this.unreadIds.indexOf(id), 1)
            }
        },
        unreadComment(id) {
            if (!this.unreadIds.includes(id)) {
                this.unreadIds.push(id)
            }
        },
        reversed(comments) {
            return [...comments].reverse()
        },
        pushComment(comment) {
            if (this.direction === "column") {
                this.displayedComments.unshift(comment)
            } else {
                this.displayedComments.push(comment)
            }
            if (this.unreadIds.length === 1) {
                EventBus.$emit("forse-read:comment", this.unreadIds[0])
            }
        },
        updateComments(newCommentId) {
            this.getComments(newCommentId, true)
            if (this.commentable_type === "quiz-attempt") {
                this.$emit("update:comments", newCommentId)
            }
        },
        updateComment(index, comment) {
            this.displayedComments[index] = comment
            this.forseRefresh = true
            this.$nextTick(() => {
                this.forseRefresh = false
            })
        },
        async getComments(newCommentId, preventLoading = false) {
            if (this.commentable_type === "test") {
                this.loadingGetComment = false
                return
            }

            if (!preventLoading) {
                this.loadingGetComment = true
            }

            try {
                const params = JSON.parse(JSON.stringify(this.query))
                let url = `comments/lesson/${this.lessonId}`

                if (this.activeCommentId) {
                    params["comment_id"] = this.activeCommentId
                }

                let isAllComments = false

                if (this.commentable_type === "quiz-attempt") {
                    if (!this.rootAttemptId && this.isPublic) {
                        isAllComments = true
                        url = `lessons/${this.lessonId}/questions/${this.questionId}/comments`
                    } else {
                        const userId = this.viewedUser ? this.viewedUser.id : this.user.id
                        if (this.isOther) {
                            url = `comments/quiz-attempt/${this.rootAttemptId}?exclude_user_id=${userId}`
                        } else {
                            if (!this.rootAttemptId && !newCommentId) {
                                return
                            }
                            url = `comments/quiz-attempt/${this.rootAttemptId}?user_id=${userId}`
                        }
                        if (this.questionId) {
                            url += `&quiz_question_id=${this.questionId}`
                        }
                    }
                }

                if (this.commentable_type === "info_units" && this.unitId) {
                    url = `comments/info_units/${this.unitId}`
                }

                if (this.parent === "student" && !isAllComments) {
                    url = `/public/${url}`
                }

                if (this.direction === "column-reverse") {
                    params.sort_type = "asc"
                }

                const response = await axios.get(url, { params })

                if (this.parent === "student") {
                    const payload = response.data?.comments || response.data
                    const { data, links, meta } = payload

                    this.comments = data
                    this.nextPageLink = links.next
                    this.prevPageLink = links.prev
                    this.query.page = meta.current_page
                } else {
                    const { comments, unread_ids } = response.data
                    const { data, links, meta } = comments
                    this.comments = data
                    this.nextPageLink = links.next
                    this.prevPageLink = links.prev
                    this.query.page = meta.current_page
                    this.unreadIds = unread_ids
                }
            } catch (e) {
                console.log(e)
            } finally {
                if (!preventLoading) {
                    this.loadingGetComment = false
                }
            }
        },
        getLangs() {
            let timerInterval = null
            timerInterval = setInterval(() => {
                if (this.$parent && this.$parent.langsKeys) {
                    clearInterval(timerInterval)
                    this.langsKeys = this.$parent.langsKeys
                }
            }, 1000)
        },
        async getCurators() {
            if (this.courseId) {
                try {
                    let url = `/courses/${this.courseId}/user-curators`
                    if (this.quizId) {
                        url += `?quiz=${this.quizId}`
                    }
                    if (this.lessonId) {
                        if (this.quizId) {
                            url += "&"
                        } else {
                            url += "?"
                        }
                        url += `lesson_id=${this.lessonId}`
                    }
                    const res = await axios.get(url)
                    this.curators = res.data.data
                    this.curatorPublicFace = this.curators.find(curator => curator.is_public_user)
                    this.curatorsUpdateCount++
                } catch (e) {
                    console.log("get curators error:", e)
                }
            }
        }
    },
    computed: {
        displayedComments() {
            return this.comments
        },
        createStyle() {
            const shadow = {
                boxShadow: "0px 4px 10px rgba(128, 158, 191, 0.15)",
                borderRadius: "10px",
                padding: "15px 5px 30px 5px",
                marginBottom: "10px"
            }
            const style = this.stickyBottom
                ? {
                      position: "sticky",
                      zIndex: 1,
                      bottom: 0,
                      backgroundColor: "#ffffff",
                      marginTop: "10px",
                      ...shadow
                  }
                : {
                      ...shadow
                  }

            if (this.noShadow) {
                delete style.boxShadow
                delete style.padding
                delete style.marginBottom
            }

            return style
        }
    },
    watch: {
        displayBulkDeleteModal(val) {
            EventBus.$emit(EventsList.BLUR_FULL_CONTENT, val)
        }
    }
}
</script>

<style scoped lang="sass">

.comments-public
    height: max-content
    &__content
        height: 100%
        display: flex
        flex-direction: column
    &_reversed
        &::v-deep
            .comments-create
                .comments-create-type__options
                    top: auto
                    bottom: 100%
    &__no-auth
        margin-bottom: 20px
        font-weight: 500
        font-size: 14px
        text-decoration: underline
        text-underline-offset: 4px
        color: #ff7439
        line-height: 150%
    &__title
        margin-bottom: 20px
        font-family: 'Ubuntu', serif
        font-weight: 500
        font-size: 16px
        line-height: 130%
        color: #151515
        @media screen and (max-width: 640px)
            font-size: 14px
</style>
<style lang="sass">
.comments-public-scroll
    .simplebar-vertical
        width: 4px !important
    .simplebar-horizontal
        visibility: hidden !important
</style>
