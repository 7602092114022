<template>
    <div class="mt-20">
        <div v-if="isSeparate() || displayAll" class="practice-actions-container">
            <button v-if="isAllowPrevious()" @click="$emit('prev')" class="practice-next-action mr-20">
                <span>←</span> {{ t("expert.back") }}
            </button>
            <div v-else-if="!isOneQuestion()"></div>
            <button
                @click="$emit(isNotLast() ? 'next' : 'finish')"
                :class="{ disabled: disabled, 'pointer-events-none': loaders && loaders.finish }"
                class="practice-next-action"
            >
                <SpinLoader color="#fff" :width="28" v-if="loaders && loaders.finish" />
                <template v-else-if="!isSeparate()"> {{ t("create-course.send") }} </template>
                <template v-else-if="isOneQuestion()"> {{ t("common.answer") }} </template>
                <template v-else-if="isNotLast()"> {{ t("course.practice.next") }} <span>→</span> </template>
                <template v-else> {{ t("course.practice.finish") }} </template>
            </button>
        </div>
        <div class="practice-actions-container practice-actions-container_center mt-20">
            <button v-if="isAllowClear()" @click="$emit('clear')" class="practice-clear-action">
                {{ t("create-course.clear_form") }}
            </button>
            <button v-if="isAllowFinish()" @click="$emit('finish')" class="practice-finish-action">
                {{ t("course.practice.finish") }}
            </button>
        </div>
    </div>
</template>

<script>
import { QUESTION_DISPLAY_TYPES } from "@components/Practice/common/_types"
import SpinLoader from "@components/Loaders/SpinLoader.vue"
import { isInvalidQuestion } from "@components/Practice/PracticeAttempt/practice-attempt-mixin"

export default {
    components: { SpinLoader },
    props: {
        displayAll: {
            type: Boolean,
            default: false
        },
        question: {
            type: Object,
            default: null
        },
        interactive: {
            type: Boolean,
            default: true
        },
        loaders: {
            type: Object,
            default: null
        },
        quiz: {
            type: Object,
            default: null
        }
    },
    name: "PracticeActionsContainer",
    data() {
        return {
            QUESTION_DISPLAY_TYPES,
            disabled: false
        }
    },
    created() {
        this.disabled = this.isDisabled()
    },
    methods: {
        isNotLast() {
            return this.quiz.questions.indexOf(this.question) !== this.quiz.questions.length - 1
        },
        isPreLast() {
            return this.quiz.questions.indexOf(this.question) === this.quiz.questions.length - 2
        },
        isNotFirst() {
            return this.quiz.questions.indexOf(this.question) !== 0
        },
        isAllowFinish() {
            if (this.isSeparate() && this.isNotLast()) {
                if (this.quiz.finish_type_id === 1) {
                    return true
                }
                if (
                    this.quiz.questions.filter(question => question.has_result_answer).length ===
                    this.quiz.questions.length - 1
                ) {
                    if (this.isPreLast() && !this.interactive) {
                        return false
                    }
                    return true
                }
            }

            return false
        },
        isAllowClear() {
            return !this.isSeparate() && this.quiz.is_allow_clear && this.displayAll
        },
        isAllowPrevious() {
            return this.isSeparate() && this.isNotFirst() && this.quiz.reanswer_type !== 1
        },
        isSeparate() {
            return this.quiz.questions_display_type_id === QUESTION_DISPLAY_TYPES.SEPARATELY
        },
        isOneQuestion() {
            return this.quiz.questions.length === 1
        },
        isInvalid(question) {
            return isInvalidQuestion(question)
        },
        isDisabled() {
            if (this.isSeparate()) {
                return this.isInvalid(this.question)
            } else {
                return !!this.quiz.questions.map(this.isInvalid).find(invalid => !!invalid)
            }
        }
    },
    watch: {
        quiz: {
            deep: true,
            handler() {
                if (!this.isSeparate()) {
                    this.disabled = this.isDisabled()
                }
            }
        },
        question: {
            deep: true,
            handler() {
                if (this.isSeparate()) {
                    this.disabled = this.isDisabled()
                }
            }
        }
    }
}
</script>

<style scoped lang="sass">
.practice-actions-container
    display: flex
    justify-content: space-between
    align-items: center
    &_center
        justify-content: center
.practice-clear-action
    background: #FFF
    color: #000
    text-underline-offset: 4px
    &:hover
        text-decoration: underline
.practice-finish-action
    border: 1px solid #DD4141
    box-shadow: 0px 4px 10px 0px #809EBF26
    color: #DD4141
    &:hover
        color: #FFF
        background: #DD4141
.practice-next-action
    border: 1px solid #3965FF
    box-shadow: 0px 4px 10px 0px #809EBF26
    color: #3965FF
    &:hover
        color: #FFF
        background: #2E54DC
.practice-next-action, .practice-clear-action, .practice-finish-action
    min-width: 135px
    height: 45px
    border-radius: 4px
    display: flex
    user-select: none
    align-items: center
    justify-content: center
    font-size: 14px
    font-weight: 400
    line-height: 20px
    padding: 0 10px
    transition: .2s
    font-family: 'Lato', sans-serif
    span
        font-family: 'Lato', sans-serif
        margin-left: 5px
        margin-right: 5px
</style>
