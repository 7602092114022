<template>
    <div class="lesson-unavailable">
        <h3 class="limited">
            {{ t(auth ? "create-course.access_denied" : "create-course.access_denied_no_auth") }}
        </h3>

        <default-description
            v-html="format(lessonDto.blocked_info.tooltip_message, lessonDto.blocked_info.access_date)"
            :line-height="28"
            :size="20"
            v-if="isBlocked"
        ></default-description>

        <default-description
            v-html="format(lessonDto.closed_info.tooltip_message, lessonDto.closed_info.access_date)"
            :line-height="28"
            :size="20"
            v-else-if="isClosed"
        ></default-description>

        <default-description :line-height="28" :size="20" v-if="isBlocked && lessonDto.blocked_info.link">
            <a :href="lessonDto.blocked_info.link">{{ t("expert.card.move_1") }} →</a>
        </default-description>
    </div>
</template>

<script>
import DefaultDescription from "@components/Typography/DefaultDescription.vue"

export default {
    components: { DefaultDescription },
    props: {
        lessonDto: {
            type: Object,
            default: null
        }
    },
    name: "LessonUnavailable",
    methods: {
        format(info, date) {
            return info.replaceAll(":date", date || "")
        }
    },
    computed: {
        auth() {
            return !!window.user
        },
        isBlocked() {
            return (
                this.lessonDto.blocked_info &&
                !this.lessonDto.blocked_info.is_accessible &&
                this.lessonDto.course_access.is_course_subscribed
            )
        },
        isClosed() {
            return (
                this.lessonDto.closed_info &&
                !this.lessonDto.closed_info.is_accessible &&
                this.lessonDto.course_access.is_course_subscribed
            )
        }
    }
}
</script>

<style scoped lang="sass">
.lesson-unavailable
    margin-bottom: 50px
.limited
    text-align: center
    display: flex
    align-items: flex-end
    justify-content: center
    height: 120px
    font-size: 170%
    font-weight: 600
    font-family: Inter, sans-serif
    padding-bottom: 20px
.default-description
    margin: 15px auto 0
    text-align: center
    max-width: 720px
    font-weight: 400
    line-height: 24px
    font-family: Inter, sans-serif
</style>
