export default class Debouncer {
    #_timeout = null
    exec(callback, delay) {
        if (this.#_timeout) {
            clearTimeout(this.#_timeout)
        }
        this.#_timeout = setTimeout(() => {
            callback()
            clearTimeout(this.#_timeout)
        }, delay)
    }
}
