<template>
    <div class="practice-conformity-one-to-one">
        <div v-if="mount" class="conformity-container">
            <transition-group
                :style="{
                    gridAutoFlow: 'column',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gridTemplateRows: `repeat(${sortedAnswers.length}, auto)`
                }"
                tag="div"
                name="conformity-animation"
                class="conformity-block"
            >
                <flex-container
                    align="center"
                    class="conformity-item-container"
                    :key="`statement-${statement.id}`"
                    v-for="(statement, statement_index) in sortedStatements"
                >
                    <div>
                        <AttemptResultStudentWidget
                            class="mr-10"
                            :attempt-id="attempt && attempt.id"
                            v-if="getCommentable(statement) && getCommentable(statement).has_comments && !expertView"
                            :commentable-id="getCommentable(statement).id"
                            :comments-count="getCommentable(statement).comments_count"
                            :commentable-type="'statement_id'"
                        />
                        <AttemptResultExpertWidget
                            class="mr-10"
                            :statement="statement"
                            v-bind="widget(statement)"
                            v-else-if="expertView"
                        />
                    </div>

                    <div
                        @click="handleClick(statement)"
                        class="conformity-item"
                        :data-statement-id="statement.id"
                        :class="[
                            {
                                'pointer-events-none': !interactive || readonly,
                                disabled: dragged && dragged.type === 'statement' && statement !== dragged
                            },
                            `conformity-item_${getStatementModifier(statement_index, statement)}`
                        ]"
                    >
                        <img v-if="statement.image" :src="statement.image.url" alt="" />
                        {{ statement.text }}
                    </div>

                    <transition-group name="scale-x">
                        <svg
                            style="transition-delay: 0.1s; transform-origin: left"
                            v-if="question.binds && question.binds[statement.id]"
                            :key="statement.id"
                            class="conformity-line"
                        >
                            <line
                                stroke-width="2"
                                :x1="0"
                                :y1="0"
                                :x2="120"
                                :y2="0"
                                :stroke="getStroke(statement.id)"
                            />
                        </svg>
                    </transition-group>
                </flex-container>

                <flex-container
                    align="center"
                    :key="`answer-${answer.id}`"
                    v-for="(answer, answer_index) in sortedAnswers"
                    class="conformity-item-container"
                >
                    <div
                        @click="handleClick(answer)"
                        class="conformity-item"
                        :data-answer-id="answer.id"
                        :class="[
                            {
                                'pointer-events-none': !interactive || readonly,
                                disabled: dragged && dragged.type === 'answer' && answer !== dragged
                            },
                            `conformity-item_${getAnswerModifier(answer_index, answer)}`
                        ]"
                    >
                        <img v-if="answer.image" :src="answer.image.url || answer.image" alt="" />
                        {{ answer.text }}
                    </div>
                </flex-container>
            </transition-group>
        </div>

        <!-- RESULT -->
        <div
            class="correct-binds pointer-events-none"
            v-if="!interactive && typeShow === 'full_information' && !expertView"
        >
            <p class="practice-question-title mt-20 mb-15">
                {{ t("quiz.correct_answer") }}
            </p>
            <div class="conformity-container">
                <transition-group
                    :style="{
                        gridAutoFlow: 'column',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        gridTemplateRows: `repeat(${sortedAnswers.length}, auto)`
                    }"
                    tag="div"
                    name="conformity-animation"
                    class="conformity-block"
                >
                    <flex-container
                        align="center"
                        class="conformity-item-container"
                        :key="statement.id"
                        v-for="statement in getCorrectStatements()"
                    >
                        <div
                            @click="handleClick(statement)"
                            class="conformity-item"
                            :data-statement-id="statement.id"
                            :class="[
                                {
                                    'pointer-events-none': !interactive || readonly,
                                    disabled: dragged && dragged.type === 'statement' && statement !== dragged
                                },
                                `conformity-item_green`
                            ]"
                        >
                            <img v-if="statement.image" :src="statement.image.url" alt="" />
                            {{ statement.text }}
                        </div>

                        <transition-group name="scale-x">
                            <svg
                                style="transition-delay: 0.1s; transform-origin: left"
                                :key="statement.id"
                                class="conformity-line"
                            >
                                <line stroke-width="2" :x1="0" :y1="0" :x2="120" :y2="0" :stroke="'#15A06E'" />
                            </svg>
                        </transition-group>
                    </flex-container>

                    <flex-container
                        align="center"
                        :key="answer.id"
                        v-for="answer in getCorrectAnswers()"
                        class="conformity-item-container"
                    >
                        <div
                            @click="handleClick(answer)"
                            class="conformity-item"
                            :data-answer-id="answer.id"
                            :class="[
                                {
                                    'pointer-events-none': !interactive || readonly,
                                    disabled: dragged && dragged.type === 'answer' && answer !== dragged
                                },
                                `conformity-item_green`
                            ]"
                        >
                            <img v-if="answer.image" :src="answer.image.url || answer.image" alt="" />
                            {{ answer.text }}
                        </div>
                    </flex-container>
                </transition-group>
            </div>
        </div>

        <div v-if="!interactive && typeShow === 'only_correct_or_not'">
            <PracticeCorrectStatusAlert
                :is-correct="isCorrect"
                :is-incorrect="isIncorrect"
                :is-skipped="isSkipped"
                :is-not-started="isNotStarted"
            />
        </div>
    </div>
</template>

<script>
import ConformityMixin from "@components/Practice/PracticeQuestions/components/mixins/conformity-mixin"
import PracticeCorrectStatusAlert
    from "@components/Practice/PracticeQuestions/components/PracticeCorrectStatusAlert.vue"
import cloneDeep from "lodash.clonedeep"
import FlexContainer from "../../../Containers/FlexContainer"
import AttemptResultExpertWidget from "../../common/result/AttemptResultExpertWidget"
import AttemptResultStudentWidget from "../../common/result/AttemptResultStudentWidget"
import ClickOutside from "vue-click-outside"

export default {
    // eslint-disable-next-line vue/no-unused-components
    components: { PracticeCorrectStatusAlert, AttemptResultExpertWidget, FlexContainer, AttemptResultStudentWidget },
    directives: {
        ClickOutside
    },
    mixins: [ConformityMixin],
    props: {
        statements: {
            type: Array,
            default: () => []
        },
        answers: {
            type: Array,
            default: () => []
        },
        results: {
            type: Array,
            default: () => []
        },
        question: {
            type: Object,
            default: () => {}
        },
        interactive: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        viewable: {
            type: Boolean,
            default: false
        },
        typeShow: {
            type: String,
            default: ""
        },
        expertView: {
            type: Boolean,
            default: false
        },
        attempt: {
            type: Object,
            default: () => {}
        },
        lesson: {
            type: Object,
            default: () => {}
        },
        quiz: {
            type: Object,
            default: () => {}
        }
    },
    name: "PracticeConformityOneToOne",
    data() {
        return {
            dragged: null,
            conformityAnswers: [],
            sortedStatements: [],
            sortedAnswers: [],
            dontCallout: true,
            mount: false
        }
    },
    created() {
        this.conformityAnswers = cloneDeep(this.answers)
        this.sortedStatements = cloneDeep([...this.statements])
        this.sortedAnswers = cloneDeep([...this.answers])

        if (this.question.is_required) {
            this.question.is_allowed = false
            this.$emit("update:action")
        }

        if (!this.question.binds) {
            this.$emit("set:empty")
        }

        if (!this.interactive || this.viewable) {
            if (this.results) {
                for (const result of this.results) {
                    if (!this.viewable || (this.viewable && result.quiz_statement_id)) {
                        this.question.binds[result.quiz_statement_id] = result.quiz_answer_id
                    }
                }
            }
            this.$nextTick(() => {
                this.renderBinds()
                setTimeout(() => {
                    this.dontCallout = false
                }, 300)
            })
        }
    },
    mounted() {
        this.mount = true
    },
    methods: {
        sortStatements() {
            const compareStatements = (statement1, statement2) => {
                if (this.question.binds[statement1.id] && this.question.binds[statement2.id]) {
                    return 0
                } else if (this.question.binds[statement1.id] && !this.question.binds[statement2.id]) {
                    return 1
                } else if (!this.question.binds[statement1.id] && this.question.binds[statement2.id]) {
                    return -1
                } else if (!this.question.binds[statement1.id] && !this.question.binds[statement2.id]) {
                    return 0
                }

                return 0
            }

            if (this.interactive) {
                this.sortedStatements = [...this.sortedStatements]
                    .sort(compareStatements)
                    .map((statement, index) => ({ ...statement, index, answer: this.question.binds[statement.id] }))
            } else {
                const statements = []

                for (const sortedAnswer of this.sortedAnswers) {
                    const statementResult = this.results.find(
                        result => sortedAnswer.id === result.quiz_answer_id
                    )?.quiz_statement_id
                    statements.push(
                        statementResult ? this.statements.find(statement => statement.id === statementResult) : null
                    )
                }

                const fillNullable = statements => {
                    for (let i = 0; i < statements.length; i++) {
                        if (statements[i] === null) {
                            statements[i] = this.statements.filter(s => !statements.find(st => st && st.id === s.id))[0]
                        }
                    }
                }

                fillNullable(statements)

                this.sortedStatements = statements
            }

            const compareAnswers = (answer1, answer2) => {
                const statement1 = this.sortedStatements.find(
                    statement => statement && statement.answer && statement.answer === answer1.id
                )

                const statement2 = this.sortedStatements.find(
                    statement => statement && statement.answer && statement.answer === answer2.id
                )

                if (statement1 && !statement2) {
                    return 1
                }

                if (statement2 && !statement1) {
                    return -1
                }

                if (statement1 && statement2) {
                    return statement1.index > statement2.index ? 1 : -1
                }

                return 0
            }

            if (this.interactive) {
                this.sortedAnswers = [...this.sortedAnswers].sort(compareAnswers)
            }

            this.question.sortedAnswers = this.sortedAnswers.map(statement => statement.id)
        },
        hideAllStatements(ev) {
            if (
                ev &&
                ev.target &&
                (ev.target.classList.contains("statements-modal-item") ||
                    ev.target.classList.contains("order-statement") ||
                    ev.target.closest(".conformity-item"))
            ) {
                return
            }
            for (const statement of this.sortedStatements) {
                statement.is_open = false
            }
            this.$forceUpdate()
        },
        handleStatementClick(statement) {
            if (!this.interactive) {
                return
            }
            if (statement.is_open) {
                statement.is_open = false
                this.$forceUpdate()
                return
            }

            this.hideAllStatements()
            statement.is_open = true

            this.$forceUpdate()
        },
        removeBind(answer, statement) {
            if (!this.interactive) {
                return
            }
            if (this.question.binds[statement.id]) {
                const id = this.question.binds[statement.id]
                this.conformityAnswers.splice(
                    this.conformityAnswers.length,
                    0,
                    this.answers.find(answer => answer.id === id)
                )
                delete this.question.binds[statement.id]
                this.renderBinds()
                this.checkAllowed()
                this.$forceUpdate()

                this.$emit("callout")
            }
        },
        handleAnswerClick(answer, statement) {
            if (!this.interactive) {
                return
            }
            if (this.question.binds[statement.id]) {
                const id = this.question.binds[statement.id]
                this.conformityAnswers.splice(
                    this.conformityAnswers.length,
                    0,
                    this.answers.find(answer => answer.id === id)
                )
            }
            const index = this.conformityAnswers.indexOf(answer)
            this.question.binds[statement.id] = answer.id
            this.conformityAnswers.splice(index, 1)
            this.renderBinds()
            this.checkAllowed()
            this.hideAllStatements()
            this.$emit("callout")
        },
        getCorrectBindAnswer(statement) {
            return statement.answers[0]
        },
        getBindAnswer(statement) {
            if (this.question.binds[statement.id]) {
                const id = this.question.binds[statement.id]
                return this.answers.find(answer => answer.id === id)
            }
            return null
        },
        widget(statement) {
            return {
                expertView: this.expertView,
                typeShow: this.quiz?.result_attempt?.type_show,
                lessonId: this.lesson?.id || 0,
                questionId: this.question.id,
                question: this.question,
                attemptId: this.attempt?.id || 0,
                statement
            }
        },
        getCorrectStatements() {
            return [...this.sortedStatements]
        },
        getCorrectAnswers() {
            return [...this.getCorrectStatements().map(statement => statement.answers[0])]
        },
        getAnswerModifier(index, answer) {
            if (!this.question.binds) {
                return ""
            }
            if (
                this.interactive ||
                this.typeShow === "simple_answer" ||
                this.typeShow === "only_correct_or_not_question"
            ) {
                return answer === this.dragged || Object.values(this.question.binds).includes(answer.id) ? "active" : ""
            }
            const result = this.results.find(result => result.quiz_answer_id === answer.id && result.quiz_statement_id)

            if (result) {
                return result.is_correct ? "green" : "red"
            }
            return ""
        },

        getStatementModifier(index, statement) {
            if (
                this.interactive ||
                this.typeShow === "simple_answer" ||
                this.typeShow === "only_correct_or_not_question"
            ) {
                return statement === this.dragged ||
                    (statement && statement.id) === (this.dragged && this.dragged.id) ||
                    (this.question.binds && this.question.binds.hasOwnProperty(statement.id))
                    ? "active"
                    : ""
            }
            const result = this.results.find(result => result.quiz_statement_id === statement.id)
            if (result) {
                return result.is_correct ? "green" : "red"
            }
            return ""
        },
        getCommentable(statement) {
            return statement
        },
        getStroke(id) {
            if (
                this.interactive ||
                this.typeShow === "simple_answer" ||
                this.typeShow === "only_correct_or_not_question"
            ) {
                return "#3965FF"
            }

            const result = this.results.find(result => result.quiz_statement_id === parseInt(id))

            if (result) {
                return result.is_correct ? "#15A06E" : "#DD4141"
            }
        },
        handleClick(item) {
            const answers = Object.values(this.question.binds)
            const statements = Object.keys(this.question.binds)

            const connectLastStatement =
                item.type === "statement" &&
                !statements.includes(item.id.toString()) &&
                statements.length === this.statements.length - 1

            if (connectLastStatement) {
                const answer = this.answers.find(answer => !answers.includes(answer.id))
                if (answer) {
                    this.question.binds[item.id] = answer.id
                    this.renderBinds()
                    return
                }
            }

            const connectLastAnswer =
                item.type === "answer" && !answers.includes(item.id) && answers.length === this.answers.length - 1

            if (connectLastAnswer) {
                const statement = this.statements.find(statement => !statements.includes(statement.id.toString()))
                if (statement) {
                    this.question.binds[statement.id] = item.id
                    this.renderBinds()
                    return
                }
            }

            if (item === this.dragged) {
                this.dragged = null
                this.checkAllowed()
                return
            }

            if (!this.dragged) {
                if (item.type === "statement" && statements.includes(`${item.id}`)) {
                    delete this.question.binds[`${item.id}`]
                    this.$forceUpdate()
                    this.renderBinds(true)
                    return
                }
                if (item.type === "answer" && answers.includes(item.id)) {
                    const index = answers.indexOf(item.id)
                    delete this.question.binds[statements[index]]
                    this.$forceUpdate()
                    this.renderBinds(true)
                    return
                }

                this.dragged = item
            } else {
                if (item.type === "answer") {
                    const answers = Object.values(this.question.binds)
                    if (answers.includes(item.id)) {
                        const index = answers.indexOf(item.id)
                        delete this.question.binds[statements[index]]
                        this.$forceUpdate()
                        this.renderBinds()
                    }

                    this.question.binds[this.dragged.id] = item.id
                } else {
                    this.question.binds[item.id] = this.dragged.id
                }
                this.renderBinds()
                this.dragged = null
            }
        },
        renderBinds(isRemove = false) {
            if (!isRemove) {
                this.sortStatements()
            }
            if (!this.dontCallout) {
                this.$emit("callout")
            }

            this.checkAllowed()
        },
        checkAllowed() {
            if (this.question.is_required) {
                this.question.is_allowed = !!Object.keys(this.question.binds).length
                this.$emit("update:action")
            }
        }
    }
}
</script>

<style scoped lang="sass">
.conformity-line-container
    position: absolute
    top: 0
    left: 0
    bottom: 0
    right: 0
    pointer-events: none
.conformity-block
    display: grid
    grid-template-columns: 1fr 1fr
    grid-gap: 10px 120px
    width: 100%
.conformity-grid
    display: grid
    grid-template-columns: 1fr 120px 1fr
    width: 100%
.conformity-animation-move
    transition: all 0.4s

.conformity-mobile
    .conformity-item
        &:not(&:last-child), &-container
            margin-bottom: 20px

.conformity-container
    display: flex
    margin-top: 15px
    position: relative

    .conformity-line
        position: absolute
        pointer-events: none
        width: 120px

    .conformity-item
        box-shadow: 0 0 15px rgba(128, 158, 191, 0.15)
        border-radius: 7px
        background: #fff
        text-align: center
        padding: 10px
        border: 1px solid transparent
        cursor: pointer
        color: #52565C
        font-size: 13px
        line-height: 17px
        font-weight: 400
        transition: .3s
        width: 100%

        &_active
            background: #FBFCFF
            border-color: #3965FF

        &_red
            background: #FFF6F6
            border-color: #DD4141

        &_green
            background: #F9FEFC
            border-color: #15A06E

        &:hover
            color: #151515

        .default-description
            pointer-events: none

        img
            height: auto
            max-width: 300px
            display: block
            margin: 10px auto
</style>
