<template>
    <div class="pdf-viewer">
        <div class="viewerContainer">
            <div class="pdfViewer"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        path: {
            type: String,
            default: ""
        }
    },
    name: "PdfViewer",
    data() {
        return {
            pdf: null,
            currentPage: 1
        }
    },
    mounted() {
        setTimeout(() => this.onViewerInit())
    },
    methods: {
        onViewerInit() {
            const pdfJs = window.pdfjsLib

            pdfJs.GlobalWorkerOptions.workerSrc = `https://cdn.jsdelivr.net/npm/pdfjs-dist@3.11.174/legacy/build/pdf.worker.min.js`

            const pdfJsViewer = window["pdfjs-dist/web/pdf_viewer"]

            const container = this.$el.querySelector(".viewerContainer")
            const viewer = this.$el.querySelector(".viewer")

            const SEARCH_FOR = ""

            const CMAP_URL = "node_modules/pdfjs-dist/cmaps/"
            const CMAP_PACKED = true

            const eventBus = new pdfJsViewer.EventBus()

            const pdfLinkService = new pdfJsViewer.PDFLinkService({
                eventBus
            })

            // (Optionally) enable find controller.
            // eslint-disable-next-line no-unused-vars
            const pdfFindController = new pdfJsViewer.PDFFindController({
                eventBus,
                linkService: pdfLinkService
            })

            const pdfSinglePageViewer = new pdfJsViewer.PDFViewer({
                container,
                viewer,
                eventBus
                //linkService: pdfLinkService
                //findController: pdfFindController
            })

            //pdfLinkService.setViewer(pdfSinglePageViewer)

            eventBus.on("pagesinit", function () {
                // We can use pdfSinglePageViewer now, e.g. let's change default scale.
                pdfSinglePageViewer.currentScaleValue = "page-width"

                // We can try searching for things.
                if (SEARCH_FOR) {
                    eventBus.dispatch("find", { type: "", query: SEARCH_FOR })
                }
            })

            // Loading document.
            const loadingTask = pdfJs.getDocument({
                url: this.path,
                cMapUrl: CMAP_URL,
                cMapPacked: CMAP_PACKED
            })

            loadingTask.promise.then(function (pdfDocument) {
                // Document loaded, specifying document for the viewer and
                // the (optional) linkService.
                pdfSinglePageViewer.setDocument(pdfDocument)

                //pdfLinkService.setDocument(pdfDocument, null)
            })

            /*const url = this.path
            const container = document.getElementById("viewerContainer")

            const eventBus = new pdfjsViewer.EventBus()

            pdfJs.getDocument(url).promise.then(function (pdf) {
                pdf.getPage(2).then(function (page) {
                    const viewport = page.getViewport({ scale: 1.0 })
                    const context = canvas.getContext("2d")
                    canvas.height = viewport.height
                    canvas.width = viewport.width
                    page.render({ canvasContext: context, viewport: viewport })
                })
            })
            })*/
        }
    }
}
</script>

<style lang="sass">
.pdf-viewer
    position: relative
    min-height: 70vh
    width: 100%
    .page
        width: auto !important
        margin: 0
        border-radius: 20px
        overflow: hidden

.viewerContainer
    overflow: auto
    position: absolute
    width: 100%
    height: 100%
</style>
