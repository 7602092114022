<template>
    <errors-container
        :errors="errors"
        :color="validation && validation.color"
        :are-absolute="absoluteErrors"
        :class="`default-input_${styleModifier}`"
        class="default-input"
    >
        <alert-with-question v-if="tooltip">
            <default-title :weight="500" :size="14" text-color="#151515" inter class="mb-5">
                {{ label }}
            </default-title>
            <default-title v-html="tooltip" inter :weight="400" text-color="#52565C"> </default-title>
        </alert-with-question>

        <SearchIcon v-if="bloated" class="default-input__search-icon" />

        <label
            :for="id"
            :class="[
                { 'default-input__label_flat': isFlat, 'default-input__label_static': label && label.length > 70 },
                customLabelIndent
            ]"
            class="default-input__label"
            v-if="label"
            v-html="`${label}${required ? '<em>*</em>' : ''}`"
        ></label>

        <input
            :style="resolveStyle()"
            v-if="!isArea"
            :id="id"
            :readonly="readonly"
            class="default-input__input"
            :class="[
                {
                    'default-input__input_active': focused && withBorderHover,
                    'default-input__input_error': errors.length || errorAccent,
                    'default-input__input_bloated': bloated,
                    'default-input__input_pointer': pointer,
                    'default-input__input_tooltip': tooltip,
                    'default-input__input_small-indent': smallIndent,
                    'default-input__input_small': small,
                    'bg-transparent': isTransparent
                },
                customTextIndent
            ]"
            :name="name"
            :autocomplete="autocomplete"
            autocorrect="off"
            autocapitalize="off"
            aria-autocomplete="list"
            dir="auto"
            :placeholder="showedPlaceholder"
            @focus="focused = true"
            @blur="focused = false"
            :type="type"
            ref="input"
            @keyup.enter="$emit('enter')"
            @input="handleInput"
            :value="value"
        />
        <textarea
            v-else
            :style="resolveStyle()"
            :id="id"
            ref="textarea"
            class="default-input__input default-input__input_area"
            :readonly="readonly"
            :class="[
                {
                    'default-input__input_active': focused && withBorderHover,
                    'default-input__input_error': errors.length || errorAccent,
                    'default-input__input_small-indent': smallIndent,
                    'bg-transparent': isTransparent
                },
                customTextIndent
            ]"
            :name="name"
            :autocomplete="autocomplete"
            autocapitalize="off"
            aria-autocomplete="list"
            dir="auto"
            :placeholder="showedPlaceholder"
            @focus="focused = true"
            @blur="focused = false"
            @input="handleInput"
            :value="value"
        ></textarea>
        <button
            v-if="plus"
            :class="{ disabled: !value }"
            class="inline-flex rounded-4 bg-blue plus-button"
            style="background-color: #3965ff"
            @click="$emit('plus')"
        >
            <PlusIcon fill="#fff" />
        </button>

        <!-- Custom errors -->
        <template #customErrors>
            <slot name="customErrors"></slot>
        </template>
    </errors-container>
</template>

<script>
import SearchIcon from "../../icons/SearchIcon.vue"
import AlertWithQuestion from "../Alerts/AlertWithQuestion.vue"
import ErrorsContainer from "../Containers/ErrorsContainer"
import DefaultTitle from "../Typography/DefaultTitle.vue"
import PlusIcon from "../../icons/PlusIcon.vue"

export default {
    components: { SearchIcon, PlusIcon, DefaultTitle, AlertWithQuestion, ErrorsContainer },
    props: {
        format: {
            type: [Object, Array],
            default: null
        },
        validation: {
            type: [Object, Array],
            default: null
        },
        value: {
            type: [String, Number],
            default: ""
        },
        height: {
            type: [String, Number],
            default: ""
        },
        minHeight: {
            type: [String, Number],
            default: ""
        },
        label: {
            type: String,
            default: ""
        },
        tooltip: {
            type: String,
            default: ""
        },
        pointer: {
            type: Boolean,
            default: false
        },
        bloated: {
            type: Boolean,
            default: false
        },
        plus: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        alwaysOnTop: {
            type: Boolean,
            default: false
        },
        useFocus: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        isArea: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: "text"
        },
        name: {
            type: String,
            default: ""
        },
        autocomplete: {
            type: String,
            default: "on"
        },
        placeholder: {
            type: String,
            default: ""
        },
        errors: {
            type: Array,
            default: () => []
        },
        /**
         * @values "v_2"
         **/
        styleModifier: {
            type: String,
            default: ""
        },
        smallIndent: {
            type: Boolean,
            default: false
        },
        withBorderHover: {
            type: Boolean,
            default: true
        },
        isTransparent: {
            type: Boolean,
            default: false
        },
        absoluteErrors: {
            type: Boolean,
            default: false
        },
        errorAccent: {
            type: Boolean,
            default: false
        },
        inputTextIndent: {
            type: Number,
            default: null
        }
    },
    model: {
        prop: "value",
        event: "input"
    },
    name: "DefaultInput",
    data() {
        return {
            focused: false,
            id: "input"
        }
    },
    computed: {
        isFlat() {
            return !this.value && !this.focused && !this.alwaysOnTop
        },
        showedPlaceholder() {
            if (this.label && this.isFlat) {
                return
            }
            return this.placeholder
        },
        customTextIndent() {
            return this.inputTextIndent ? `indent-${this.inputTextIndent}` : null
        },
        customLabelIndent() {
            return this.inputTextIndent ? `label-indent-${this.inputTextIndent}` : null
        }
    },
    mounted() {
        this.id = `input-${this._uid}`

        if (this.useFocus && this?.$refs?.input) {
            this.focusOnInput()
        }

        if (this.isArea && this.value && this.minHeight) {
            this.resize()
        }
    },
    methods: {
        handleInput($event) {
            this.$emit("input", $event.target.value)
            this.$emit("update", $event.target.value)
            if (this.isArea) {
                this.resize()
            }
        },
        resolveStyle() {
            const style = {}
            if (this.height) {
                style.height = this.height
            }
            if (this.minHeight) {
                style.minHeight = `${this.minHeight} !important`
            }
            if (this.errors.length && this.validation) {
                style.borderColor = this.validation.color
            }
            return style
        },
        focusOnInput() {
            setTimeout(() => {
                this.$refs.input.focus()
            })
        },
        resize() {
            const { textarea } = this.$refs

            textarea.style.height = "0"
            textarea.style.height = textarea.scrollHeight + 2 + "px"
        }
    },
    watch: {
        focused(val) {
            if (val) {
                this.$emit("focus")
            } else {
                this.$emit("blur")
            }
        },
        input(val) {
            if (val) {
                this.$emit("focus")
            } else {
                this.$emit("blur")
            }
        }
    }
}
</script>

<style scoped lang="sass">
$indentTextGap: 6px
.plus-button
    position: absolute
    right: 11px
    top: 11px
.alert-with-question
    position: absolute
    left: 8px
    top: 12px
.default-input
    position: relative
    width: 100%
    &.full-width
        .default-input__input
            max-width: 100%
    &__search-icon
        position: absolute
        left: 14px
        top: 13px
    &::after
        content: attr(data-error)
        position: absolute
        bottom: -5px
        white-space: nowrap
        font-size: 12px
        left: 0
        line-height: 12px
        color: #DD4141
    &__label
        position: absolute
        color: #B5C1D2
        font-size: 10px
        line-height: 13px
        font-weight: 600
        top: -5px
        padding: 0 4px
        background: #fff
        left: calc(32px - $indentTextGap)
        pointer-events: none
        transition: .2s

        &_flat
            font-size: 13px
            font-weight: 500
            color: #7c7c7c
            background: transparent
            line-height: 17px
            top: 14px
        &_static
            position: static
            font-size: 13px
            color: #7c7c7c
            line-height: 17px
            margin-bottom: 5px
            display: block
        &::v-deep
            em
                color: #DD4141
    &__input
        width: 100%
        border: 1px solid #E0E0EC
        border-radius: 8px
        height: 45px
        text-indent: 32px
        font-size: 13px
        font-weight: 500
        color: #52565C
        line-height: 13px
        transition: .2s
        &:focus-visible
            outline: none
        @supports (-webkit-touch-callout: none)
            font-size: 16px
            line-height: 16px
        &_bloated
            height: 45px
            width: 100%
            max-width: 240px
            border: 1px solid hsla(240, 24%, 90%, 1)
            outline: none
            border-radius: 7px
            font-size: 14px
            font-weight: 400
            font-family: Inter, sans-serif
            text-indent: 48px

        &_small
            text-indent: 14px
            height: 33px
            min-width: 200px
            &:focus
                border-color: #3965FF
        &_tooltip
            text-indent: 36px
        &_pointer
            cursor: pointer
            text-indent: 10px
            height: 38px
            max-width: 350px
            &::placeholder
                transition: .2s
                color: #B5C1D2
            &:hover
                color: #3965FF
                &::placeholder
                    color: #3965FF
                border-color: #3965FF
            &:focus
                cursor: text
                color: #000
                &::placeholder
                    color:  #B5C1D2
        &_small-indent
            text-indent: 15px
        &_area
            transition: 0s !important
            text-indent: 0 !important
            font-weight: 400 !important
            cursor: text
            overflow-y: auto
            line-height: 17px
            padding: 15px 10px 10px 30px
            font-family: 'Lato', serif
            min-height: 90px !important
            max-height: 150px !important
            &::placeholder
                font-weight: 400 !important
                color: #B5C1D2

        &_active:not(&_error)
            border-color: #97AEFF !important
        &.error
            border-color: #DD4141
            margin-bottom: 20px
        &_error
            border-color: #DD4141
        &::placeholder
            color: #B5C1D2
    &_small
        .default-input__input
            height: 35px
        .default-input__label_flat
            top: 10px
    &_v
        &_2
            .default-input__input
                border-color: #ECF1F5
                text-indent: 5px
                border-radius: 0
                border-width: 2px
                border-left: 0
                border-top: 0
                border-right: 0
                height: auto
                padding-top: 5px
                padding-bottom: 8px
            .default-input__label_flat
                top: 12px
                left: 5px
            .default-input__label
                left: 5px
        &_3
            .default-input__input
                border-color: #ECF1F5
                text-indent: 0
                border-radius: 0
                border-width: 1px
                border-left: 0
                border-top: 0
                border-right: 0
                height: auto
                padding-top: 10px
                padding-bottom: 10px
            .default-input__label_flat
                top: 12px
                left: 0
            .default-input__label
                left: 0
    // Custom text indent classes
    .indent-15
        text-indent: 15px
    // Custom label padding classes
    .label-indent-15
        left: calc(15px - $indentTextGap)
</style>
