<template>
    <div class="collapse-content" :class="{ open: isOpen, horizontal }">
        <div :style="!horizontal ? `min-height: ${min}` : `min-width: ${min}`">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "CustomCollapse",
    props: {
        isOpen: {
            required: true,
            type: [Boolean, Number]
        },
        min: {
            required: false,
            type: [Number],
            default: 0
        },
        horizontal: {
            required: false,
            type: [Boolean, Number]
        }
    },
    mounted() {
        if (this.isOpen) {
            this.checkOverflow()
        }
    },
    methods: {
        checkOverflow() {
            this.$el.style.overflow = this.isOpen ? "visible" : "hidden"
        }
    },
    watch: {
        isOpen(val) {
            setTimeout(
                () => {
                    this.checkOverflow()
                },
                val ? 320 : 0
            )
        }
    }
}
</script>

<style scoped lang="sass">
.collapse-content
    display: grid
    overflow: hidden
    transition: grid-template-rows 300ms, grid-template-columns 200ms
    grid-template-rows: 0fr !important
    &.open
        grid-template-rows: 1fr !important
    &.horizontal
        grid-template-rows: 1fr !important
        grid-template-columns: 0fr !important
        &.open
            grid-template-columns: 1fr !important
</style>
