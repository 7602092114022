<template>
    <div class="recording-audio" :class="{ 'recording-audio_full': buttonView }">
        <button
            @click="
                handleRecord({
                    audio: true
                })
            "
            :class="{ 'button-view': buttonView }"
            class="recording-audio-handler"
        >
            <RecordVoiceIcon />

            <default-title class="ml-15" :weight="500" :size="15" :text-color="'#3965FF'" v-if="buttonView">
                {{ t("course.practice.record_audio") }}
            </default-title>
        </button>

        <transition name="fade-up">
            <div v-if="error" class="recording-modal recording-modal_large">
                <default-title
                    v-html="error"
                    class="mb-10 mt-10"
                    :size="14"
                    :weight="400"
                    :text-color="'#52565C'"
                    :line-height="18"
                    font-family="Inter, sans-serif"
                >
                </default-title>

                <flex-container class="mt-10">
                    <DefaultButton
                        @click="cancelRecord()"
                        :height="40"
                        full-width
                        size="medium"
                        background="cancel"
                        :text="t('expert.cancel')"
                    />
                    <DefaultButton
                        @click="
                            handleRecord({
                                audio: true
                            })
                        "
                        :height="40"
                        full-width
                        size="medium"
                        background="green"
                        :text="t('quiz.try_again')"
                    />
                </flex-container>
            </div>
        </transition>

        <transition name="fade-up">
            <div v-if="isOpen" class="recording-modal">
                <flex-container v-show="!percent" align="center">
                    <div class="analyser-items" :style="{ gridTemplateColumns: `repeat(${num}, 5px)` }">
                        <div v-for="index of num" :key="index" class="analyser-item" />
                    </div>

                    <default-title
                        style="min-width: 40px"
                        :weight="400"
                        class="ml-10"
                        text-color="#323030"
                        font-family="Inter, sans-serif"
                    >
                        {{ formatTimer(time) }}
                    </default-title>
                </flex-container>

                <default-title
                    v-show="percent"
                    text-color="#323030"
                    class="mb-20"
                    :size="16"
                    :weight="600"
                    :line-height="25"
                    font-family="Inter, sans-serif"
                >
                    {{ t("quiz.file_saving") }}
                </default-title>

                <flex-container v-show="!percent" class="mt-10">
                    <DefaultButton
                        @click="cancelRecord()"
                        :height="40"
                        full-width
                        size="large"
                        background="cancel"
                        :text="t('expert.cancel')"
                    />
                    <DefaultButton
                        @click="stopRecord()"
                        class="ml-10"
                        :height="40"
                        full-width
                        size="large"
                        background="green"
                        :text="t('expert.add')"
                    />
                </flex-container>

                <flex-container
                    class="mb-5"
                    v-show="percent !== 100 && percent"
                    align="center"
                    style="min-height: 40px"
                    justify="space-between"
                >
                    <div class="progress-display">
                        <div
                            class="progress-display"
                            :style="{ width: `${percent}%`, backgroundColor: '#3965FF' }"
                        ></div>
                    </div>

                    <default-title
                        text-color="#3965FF"
                        class="ml-30"
                        :size="16"
                        :weight="600"
                        :line-height="25"
                        font-family="Inter, sans-serif"
                    >
                        {{ parseInt(percent) }}%
                    </default-title>
                </flex-container>

                <div class="mb-5" v-show="percent === 100">
                    <SpinLoader />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import DefaultButton from "@components/Buttons/DefaultButton.vue"
import FlexContainer from "@components/Containers/FlexContainer.vue"
import SpinLoader from "@components/Loaders/SpinLoader.vue"
import DefaultTitle from "@components/Typography/DefaultTitle.vue"
import RecordVoiceIcon from "@icons/RecordVoiceIcon.vue"

import { MediaRecorder, register } from "extendable-media-recorder"
import { connect } from "extendable-media-recorder-wav-encoder"
import lamejs from "lamejs"
import { v4 } from "uuid"
import axios from "~axios"
;(async function () {
    await register(await connect())
})()

export default {
    components: { SpinLoader, FlexContainer, DefaultTitle, DefaultButton, RecordVoiceIcon },
    props: {
        buttonView: {
            type: Boolean,
            default: false
        }
    },
    name: "RecordingAudio",
    data() {
        return {
            recorder: null,
            canceled: false,
            stream: null,
            context: null,
            frequency: new Uint8Array(40),
            num: 40,
            percent: 0,
            time: 0,
            intrval: 0,
            isOpen: false,
            name: "",
            error: ""
        }
    },
    methods: {
        handleRecord(
            constraints = {
                audio: true
            }
        ) {
            this.canceled = false
            navigator.mediaDevices.getUserMedia(constraints).then(this.onSuccess, this.onError)
        },
        onSuccess(stream) {
            this.isOpen = true
            this.error = ""

            if (this.interval) {
                clearInterval(this.interval)
                this.time = 0
            }

            this.time += 1000

            this.interval = setInterval(() => {
                this.time += 1000
            }, 1000)

            const mediaRecorder = new MediaRecorder(stream, {
                mimeType: "audio/wav"
            })

            mediaRecorder.start()

            this.stream = stream
            this.recorder = mediaRecorder

            let chunks = []

            mediaRecorder.onstop = () => {
                if (this.canceled) {
                    return
                }

                this.percent = 0.5

                try {
                    const blob = new Blob(chunks, { type: "audio/wav" })

                    new Response(blob).arrayBuffer().then(audioData => {
                        const wav = lamejs.WavHeader.readHeader(new DataView(audioData))
                        const samples = new Int16Array(audioData, wav.dataOffset, wav.dataLen / 2)
                        try {
                            this.encodeMono(wav.channels, wav.sampleRate, samples)
                        } catch (e) {
                            this.handleBlob(new Blob(chunks, { type: "audio/mp3" }), false)
                        }
                    })
                } catch (e) {
                    this.handleBlob(new Blob(chunks, { type: "audio/mp3" }), false)
                }
            }

            mediaRecorder.ondataavailable = function (e) {
                chunks.push(e.data)
            }

            this.visualizeFrequency()
        },
        onError() {
            this.error = this.t("quiz.no_devices_access")
        },
        handleBlob(blob, encoded = false) {
            this.$nextTick(() => {
                const url = window.file_storage_url
                const uuid = v4().slice(0, 12)
                const name = `${uuid + (encoded ? "-encoded" : "")}.mp3`

                const formData = new FormData()

                formData.append("file", blob, name)

                if (window.user) {
                    const { id, id_hash } = window.user
                    formData.append("user_id", id)
                    formData.append("user_id_hash", id_hash)
                }

                if (window.current_cabinet_id) {
                    formData.append("cabinet_id", window.current_cabinet_id)
                }

                this.name = name

                axios
                    .post(url, formData, {
                        onUploadProgress: progressEvent => {
                            this.percent = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                        },
                        params: { model: "comment" }
                    })
                    .then(response => {
                        const file = response.data.data

                        let checkStatus = setInterval(() => {
                            const { uuid } = file

                            axios.get(`/files/${uuid}`).then(response => {
                                let data = response.data.data
                                if (data.process_status === "completed" && !data.url.includes("fileservice")) {
                                    file.url = data.url

                                    clearInterval(checkStatus)
                                    this.cancelRecord()
                                    this.$emit("save", file)
                                }
                            })
                        }, 2000)
                    })
                    .catch(e => {
                        this.cancelRecord(e)
                    })
            })
        },
        encodeMono(channels, sampleRate, samples) {
            const buffer = []
            const mp3enc = new lamejs.Mp3Encoder(channels, sampleRate, 320)

            let remaining = samples.length
            const maxSamples = 1152

            for (let i = 0; remaining >= maxSamples; i += maxSamples) {
                const mono = samples.subarray(i, i + maxSamples)
                const mp3buf = mp3enc.encodeBuffer(mono)
                if (mp3buf.length > 0) {
                    buffer.push(new Int8Array(mp3buf))
                }
                remaining -= maxSamples
            }

            const d = mp3enc.flush()

            if (d.length > 0) {
                buffer.push(new Int8Array(d))
            }

            const blob = new Blob(buffer, { type: "audio/mp3" })

            this.handleBlob(blob, true)
        },
        visualizeFrequency() {
            if (!this.context) {
                this.context = new AudioContext()
            }

            if (!this.stream) {
                return
            }

            const source = this.context.createMediaStreamSource(this.stream)

            const analyser = this.context.createAnalyser()

            source.connect(analyser)

            this.animate(analyser)
        },
        animate(analyser) {
            const requestAnimationFrame =
                window.requestAnimationFrame ||
                window.mozRequestAnimationFrame ||
                window.webkitRequestAnimationFrame ||
                window.msRequestAnimationFrame

            this.requestAnimation = requestAnimationFrame(() => this.animate(analyser))
            analyser.getByteFrequencyData(this.frequency)

            const analyserElements = this.$el.querySelectorAll(".analyser-item")
            for (let i = 0; i < analyserElements.length; i++) {
                const height = this.frequency[i] / 8
                analyserElements[i].style.height = height + "px"
            }
        },
        formatTimer(t) {
            return new Date(t).toISOString().slice(14, 19)
        },
        cancelRecord() {
            if (this.recorder) {
                this.recorder.stop()
            }

            if (this.stream) {
                this.stream.getTracks().forEach(rec => rec.stop())
            }

            this.canceled = true
            this.isOpen = false
            this.percent = 0
            this.time = 0
            this.error = ""
        },
        stopRecord() {
            if (this.recorder) {
                this.recorder.stop()
            }
        }
    }
}
</script>

<style scoped lang="sass">
.recording-audio
    position: relative
    max-width: 460px
.recording-modal
    position: absolute
    min-width: 270px
    right: -70px
    top: 0
    background-color: #fff
    z-index: 999999
    border-radius: 10px
    box-shadow: 0 4px 10px rgba(128, 158, 191, 0.15)
    padding: 14px 12px
    &_large
        min-width: 320px
        &::v-deep
            .default-button
                padding: 0 12px !important
.analyser-items
    min-height: 40px
    display: grid
    align-items: center
    .analyser-item
        background-color: #3965FF
        width: 2px
        min-height: 3px
.recording-audio-handler
    width: 30px
    height: 30px
    display: flex
    align-items: center
    justify-content: center
    border-radius: 4px
    background-color: #fff
    transition: .2s
    z-index: 2
    @media screen and (max-width: 640px)
        width: 24px
        height: 24px
    &:hover
        background-color: #F2F7FF
    &::v-deep
        path
            fill: #3E4755
    &.button-view
        width: 100%
        &::v-deep
            path
                fill: #3965FF
.progress-display
    width: 100%
    background-color: #F3F3F3
    border-radius: 100px
    height: 5px
    transition: .2s
</style>
