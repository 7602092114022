<template>
    <div class="practice-navigation-container">
        <div class="practice-navigation">
            <div
                @click="goQuestion(item.index)"
                class="practice-navigation-item"
                :class="item.classes"
                :key="item.index"
                v-for="item in items"
            >
                {{ item.index + 1 }}
            </div>
        </div>
    </div>
</template>

<script>
import { REANSWER_TYPES } from "@components/Practice/common/_types"

export default {
    name: "PracticeQuizNavigation",
    props: {
        quiz: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            items: []
        }
    },
    created() {
        this.setItems()
    },
    methods: {
        goQuestion(index) {
            this.$emit("go", index)
            this.$nextTick(() => {
                this.setItems()
                this.$forceUpdate()
            })
        },
        setItems() {
            this.items = this.quiz.questions.map((question, index) => ({
                index,
                classes: {
                    movable: !question.completed || this.quiz.reanswer_type !== REANSWER_TYPES.NONE,
                    completed: question.completed,
                    skipped: question.is_skipped,
                    active: question.active
                }
            }))
        }
    },
    watch: {
        "quiz.questions": {
            deep: true,
            handler() {
                this.setItems()
                this.$forceUpdate()
            }
        }
    }
}
</script>

<style scoped lang="sass">
.practice-navigation
    display: grid
    grid-template-columns: repeat( auto-fit, minmax(44px, 44px) )
    grid-gap: 10px 10px
.practice-navigation-container
    margin: 20px 0
.practice-navigation-item
    height: 44px
    display: flex
    align-items: center
    justify-content: center
    background-color: #F3F3F3
    border: 1px solid #E0E0EC
    border-radius: 7px
    color: #323030
    font-family: Ubuntu, serif
    font-size: 16px
    font-weight: 500
    pointer-events: none
    transition: .2s
    &.completed:not(&.skipped)
        background-color: #F2F7FF
        border-color: #3965FF
    &.movable
        cursor: pointer
        pointer-events: all
    &.active
        pointer-events: none
    &.active, &:hover
        background-color: #FFF8F2
        border-color: #FF7439
    &.active
        background-color: #FFF8F2 !important
        border-color: #FF7439 !important
</style>
