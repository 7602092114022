<template>
    <div :style="questionStyle()" class="practice-question">
        <PracticeQuestionInfo
            v-bind="widget()"
            :expert-view="expertView"
            :number="questionNumber"
            :name="question.name"
            :question="question"
            :quiz="quiz"
        />

        <PracticeQuestionDefaultOptions
            v-if="interactive"
            :attempt="attempt"
            :quiz="quiz"
            :question="question"
            @next="$emit('next')"
        />
        <div v-else class="mb-10"></div>

        <FilesNewView v-if="question.files && question.files.length" :files="question.files" />

        <div
            v-if="isShowAnswers()"
            class="practice-question__answers"
            :class="{ 'practice-question__answers_disabled': disabledByTimer() }"
        >
            <div class="ql-editor" v-if="question.text" v-html="question.text"></div>
            <img
                style="max-height: 400px"
                v-if="question.image"
                :src="question.image.url"
                alt="image"
                class="mt-10 mb-10"
            />
            <flex-container
                :direction="
                    !isHorizontal(question.likert_scale_type) || ($screenWidth <= 480 && !isType(QUESTION_TYPES.RATING))
                        ? 'column'
                        : 'row'
                "
                align="center"
                :justify="
                    (isType(QUESTION_TYPES.REACTION) || isType(QUESTION_TYPES.RATING)) &&
                    isHorizontal(question.likert_scale_type) &&
                    $screenWidth >= 860
                        ? 'flex-start'
                        : 'center'
                "
                class="flex-wrap mb-20 practice-question-scale"
            >
                <div
                    @click="setCorrect(answer)"
                    :class="{
                        'pointer-events-none': !interactive || readonly,
                        'practice-answer_scale_active': isActive(answer),
                        'practice-answer_scale_rating': isType(QUESTION_TYPES.RATING),
                        'practice-answer_scale_full':
                            ($screenWidth <= 480 && !isType(QUESTION_TYPES.RATING)) ||
                            !isHorizontal(question.likert_scale_type)
                    }"
                    class="practice-answer_scale cursor-pointer pr-10"
                    v-for="(answer, index) in question.answers"
                    :style="[{ opacity: isAnswered(answer) ? 1 : 0.4 }, getStyle(answer)]"
                    :key="index"
                >
                    <flex-container direction="column" justify="center" align="center">
                        <img
                            style="width: 60px; height: 60px; object-fit: cover; border-radius: 8px"
                            v-if="answer.image"
                            :src="answer.image.url || answer.image"
                            alt="image"
                        />
                        <PracticeQuestionDescription class="text-center mt-15" :description="answer.text" />
                        <PracticeQuestionDescription
                            class="text-center mt-15"
                            v-if="expertView"
                            :description="getAggregated(answer)"
                        />
                    </flex-container>
                </div>
            </flex-container>
        </div>

        <PracticeCorrectStatusAlert
            v-if="!interactive"
            :correct-answers="
                !interactive &&
                isType(QUESTION_TYPES.YES_NO) &&
                typeShow() === 'full_information' &&
                isIncorrect &&
                isIncorrect() &&
                getCorrectAnswers()
            "
            :is-correct="isCorrect"
            :is-incorrect="isIncorrect"
            :is-skipped="isSkipped"
            :is-not-started="isNotStarted"
        />

        <PracticeQuestionComment v-if="isDisplayComment" :question="question" />

        <PracticeActionsContainer
            class="mt-25"
            v-if="interactive"
            :question="question"
            :quiz="quiz"
            v-on="$listeners"
        />
    </div>
</template>

<script>
import { QUESTION_TYPES } from "@components/Practice/PracticeQuestions/_types"
import PracticeCorrectStatusAlert
    from "@components/Practice/PracticeQuestions/components/PracticeCorrectStatusAlert.vue"
import FlexContainer from "../../Containers/FlexContainer"
import PracticeMixin from "./_mixin"
import PracticeActionsContainer from "./components/PracticeActionsContainer.vue"
import PracticeQuestionDefaultOptions from "./components/PracticeQuestionDefaultOptions"
import FilesNewView from "../../Files/FilesNewView"
import PracticeQuestionInfo from "./components/PracticeQuestionInfo"

export default {
    props: {
        question: {
            type: Object,
            default: () => {}
        }
    },
    components: {
        PracticeCorrectStatusAlert,
        PracticeQuestionInfo,
        PracticeActionsContainer,
        FilesNewView,
        PracticeQuestionDefaultOptions,
        FlexContainer
    },
    name: "PracticeScaleAnswer",
    mixins: [PracticeMixin],
    methods: {
        getStyle(answer) {
            const style = {}

            if (this.isActive(answer)) {
                style.background = "#F2F7FF !important"
            }

            if (this.question.type.slug === QUESTION_TYPES.RATING) {
                if (this.isActive(answer) && this.question.answers.indexOf(answer) !== 0) {
                    style.borderTopLeftRadius = 0
                    style.borderBottomLeftRadius = 0
                }

                const hasActive = this.question.answers.find(this.isActive)

                if (hasActive) {
                    const activeIndex = this.question.answers.indexOf(hasActive)
                    const currentIndex = this.question.answers.indexOf(answer)

                    if (activeIndex > currentIndex) {
                        style.background = "#F2F7FF !important"
                        style.color = "#3965FF"
                        style.opacity = "1 !important"

                        if (currentIndex === 0) {
                            style.borderTopRightRadius = 0
                            style.borderBottomRightRadius = 0
                        } else if (currentIndex !== activeIndex) {
                            style.borderTopRightRadius = 0
                            style.borderBottomRightRadius = 0
                            style.borderTopLeftRadius = 0
                            style.borderBottomLeftRadius = 0
                        }
                    }
                }
            }

            return style
        },
        isActive(answer) {
            return this.getIsActive(answer) && this.isAnswered(answer)
        },
        getIsActive(answer) {
            if (!this.interactive) {
                return !!this.isAnswered(answer)
            }
            return !!answer.is_correct
        },
        setCorrect(answer) {
            if (this.disabledByTimer() || !this.interactive) {
                return
            }

            for (const answer of this.question.answers) {
                answer.is_correct = false
            }
            answer.is_correct = true

            this.$forceUpdate()
            this.$emit("callout")
        },
        getAggregated(answer) {
            const aggregated = this.aggregateInterview.find(item => item.quiz_answer_id === answer.id)

            if (aggregated) {
                return `${aggregated.count} (${aggregated.percent}%)`
            }

            return "0 (0%)"
        }
    }
}
</script>
