<template>
    <p
        :style="{ fontSize: `${size}px`, lineHeight: `${lineHeight}px`, fontWeight: weight, ...descriptionStyle }"
        class="default-description"
        :class="{ 'default-description_linked': linked }"
        @click="$emit('click')"
    >
        <slot></slot>
    </p>
</template>

<script>
export default {
    name: "DefaultDescription",
    props: {
        size: {
            type: Number,
            default: 13,
            required: false
        },
        weight: {
            type: Number,
            default: 400,
            required: false
        },
        lineHeight: {
            type: Number,
            default: 17,
            required: false
        },
        linked: {
            type: Boolean,
            default: false,
            required: false
        },
        textColor: {
            type: String,
            default: "",
            required: false
        }
    },
    computed: {
        descriptionStyle() {
            const style = {}

            if (this.textColor) {
                style.color = this.textColor
            }

            return style
        }
    }
}
</script>

<style scoped lang="sass">
.default-description
    color: #52565C
    &.text-center
        text-align: center
    &.green
        color: #14ae76
    &.black
        color: #151515
    a:not(.text-blue):not(.text-blue2):not(.no-color)
        color: #3965FF
    a
        &.no-underline
            text-decoration: none !important
        &.orange
            color: #FF7439 !important
        &.blue
            color: #3965FF !important
        &.hover
            cursor: pointer
            &:hover
                color: #3451BE
                text-decoration: underline !important
                text-underline-offset: 3px
    strong
        color: #DD4141
        font-weight: 600
        &.blue
            color: #3965FF
    b
        font-weight: 600
        &:not(.text-green)
            color: #151515
        span
            color: #9f9f9f
    &::v-deep
        em
            background: yellow
            font-style: initial
        .blue-alert
            background: #F1F8FC
            border: 1px solid #B3DBF1
            padding: 10px
            font-size: 13px
            font-weight: 400
            line-height: 17px
            color: #52565C
            display: block
            margin-top: -8px
            border-radius: 7px
        b
            font-weight: 600
            &:not(.text-green)
                color: #151515
        span
            &.red
                color: #DD4141
            &.blue
                color: #3965FF
            &.gray-light
                color: #98A2B0
    &.alert
        &::v-deep
            em
                display: inline-block
                font-size: 12px
                color: #f94040
                background: #FFFDF5
                border: 1px solid #F9F1D0
                padding: 2px
    &.blue
        color: #3965FF
    &.black
        color: #151515
    &.dark
        color: #3C3E41
    &.gray
        color: #98A2B0

    &.hovered
        text-decoration: underline
        text-underline-offset: 1px
    &_linked
        cursor: pointer
        text-decoration: underline
        text-underline-offset: 1px
        &:hover
            color: #0231DA
</style>
