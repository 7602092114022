<template>
    <div :style="questionStyle()" class="practice-question">
        <PracticeQuestionInfo
            v-bind="widget()"
            :expert-view="expertView"
            :number="questionNumber"
            :name="question.name"
            :question="question"
            :quiz="quiz"
        />

        <PracticeQuestionDefaultOptions
            v-if="interactive"
            :attempt="attempt"
            :quiz="quiz"
            :question="question"
            @next="$emit('next')"
        />
        <div v-else class="mb-10"></div>

        <FilesNewView v-if="question.files && question.files.length" :files="question.files" />

        <div
            v-if="isShowAnswers()"
            class="practice-question__answers"
            :class="{ 'practice-question__answers_disabled': disabledByTimer() }"
        >
            <div
                @click="setCorrect(answer)"
                :class="{ 'pointer-events-none': readonly }"
                class="practice-question__answer text-answer cursor-pointer"
                v-for="(answer, index) in question.answers"
                :key="index"
            >
                <flex-container
                    :style="{
                        opacity: isAnswered(answer) ? 1 : 0.4
                    }"
                >
                    <PracticeRadio
                        :class="{
                            'with-black':
                                !interactive &&
                                (typeShow() === 'simple_answer' || typeShow() === 'only_correct_or_not_question')
                        }"
                        v-if="question.interview_type === 1"
                        :active="getIsActive(answer)"
                    />
                    <PracticeCheck
                        :class="{
                            'with-black':
                                !interactive &&
                                (typeShow() === 'simple_answer' || typeShow() === 'only_correct_or_not_question')
                        }"
                        v-if="question.interview_type === 2"
                        :active="getIsActive(answer)"
                    />
                    <div
                        :class="{
                            'practice-question__text-area-container': isCustomAnswer(answer) && answer.is_correct
                        }"
                    >
                        <template v-if="interactive">
                            <PracticeQuestionDescription
                                class="cursor-pointer"
                                :description="getTextWithCustom(answer)"
                            />

                            <textarea
                                @click.stop
                                class="practice-question__text-area"
                                v-if="isCustomAnswer(answer) && answer.is_correct"
                                @input="$emit('callout')"
                                v-model="answer.text_answer"
                                :placeholder="inlineText(answer.text)"
                                cols="60"
                                rows="7"
                            ></textarea>
                        </template>
                        <flex-container align="center" v-else>
                            <PracticeQuestionDescription
                                class="cursor-pointer"
                                :description="getTextWithCustom(answer)"
                            />
                            <span
                                v-if="!interactive && typeShow() === 'full_information' && getInterviewResult(answer)"
                                class="practice-question__text ml-5 text-blue font-bold whitespace-nowrap"
                            >
                                {{ getInterviewResult(answer).percent }}% ({{ getInterviewResult(answer).count }})
                            </span>
                        </flex-container>

                        <img
                            :style="getImageStyle(answer)"
                            v-if="answer.image"
                            :src="answer.image.url || answer.image"
                            alt="image"
                            class="mt-10"
                        />
                    </div>
                </flex-container>
            </div>
        </div>

        <PracticeCorrectStatusAlert
            v-if="!interactive"
            :is-correct="isCorrect"
            :is-incorrect="isIncorrect"
            :is-skipped="isSkipped"
            :is-not-started="isNotStarted"
        />

        <PracticeQuestionComment v-if="isDisplayComment" :question="question" />

        <PracticeActionsContainer v-if="interactive" :question="question" :quiz="quiz" v-on="$listeners" />
    </div>
</template>

<script>
import PracticeCorrectStatusAlert
    from "@components/Practice/PracticeQuestions/components/PracticeCorrectStatusAlert.vue"
import FlexContainer from "../../Containers/FlexContainer"
import PracticeRadio from "../common/inputs/PracticeRadio"
import PracticeMixin from "./_mixin"
import PracticeActionsContainer from "./components/PracticeActionsContainer.vue"
import PracticeQuestionDefaultOptions from "./components/PracticeQuestionDefaultOptions"
import FilesNewView from "../../Files/FilesNewView"
import PracticeCheck from "../common/inputs/PracticeCheck"
import PracticeQuestionInfo from "./components/PracticeQuestionInfo"

export default {
    props: {
        aggregateInterview: {
            type: Array,
            default: () => []
        }
    },
    components: {
        PracticeCorrectStatusAlert,
        PracticeActionsContainer,
        PracticeQuestionInfo,
        PracticeCheck,
        FilesNewView,
        PracticeQuestionDefaultOptions,
        PracticeRadio,
        FlexContainer
    },
    mixins: [PracticeMixin],
    name: "PracticeInterviewAnswer",
    methods: {
        getIsActive(answer) {
            if (!this.interactive) {
                if (this.typeShow() === "simple_answer" || this.typeShow() === "only_correct_or_not_question") {
                    return !!this.isAnswered(answer)
                }
                if (this.typeShow() === "only_correct_or_not" || this.typeShow() === "full_information") {
                    return this.isAnswered(answer)?.is_correct
                }
            }
            return !!answer.is_correct
        },
        getInterviewResult(answer) {
            return this.aggregateInterview.find(a => a.quiz_answer_id === answer.id)
        },
        setCorrect(answer) {
            if (this.disabledByTimer() || !this.interactive) {
                return
            }
            if (this.question.interview_type === 2) {
                answer.is_correct = !answer.is_correct
                this.$forceUpdate()
                this.$emit("callout")
                return
            }

            for (const answer of this.question.answers) {
                answer.is_correct = false
            }

            answer.is_correct = true

            this.$forceUpdate()
            this.$emit("callout")
        }
    }
}
</script>
