<template>
    <label :class="{ 'pointer-events-none': fileLoading, 'button-view': buttonView }" class="attach-handler">
        <AttachIcon v-if="!fileLoading" />
        <LoaderIcon v-else />
        <default-title v-if="buttonView" class="ml-15" :weight="500" :size="15" :text-color="'#3965FF'">
            {{ t("course.practice.attach_file") }}
        </default-title>
        <input
            class="attach-handler__input"
            type="file"
            hidden
            :multiple="multiple"
            name="file"
            ref="file"
            accept="*.*"
            @change="handleFileUpload"
        />
    </label>
</template>

<script>
import LoaderIcon from "@components/Loaders/LoaderIcon.vue"
import DefaultTitle from "@components/Typography/DefaultTitle.vue"
import AttachIcon from "@icons/AttachIcon.vue"
import axios from "~axios"
import dataURItoBlob from "../../utils/Helpers/dataURItoBlob"

export default {
    props: {
        appModel: {
            type: String,
            default: "comment"
        },
        buttonView: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        }
    },
    name: "UploadFile",
    components: { DefaultTitle, LoaderIcon, AttachIcon },
    data() {
        return {
            file: null,
            fileLoading: false
        }
    },
    methods: {
        handleFileUpload() {
            for (const file of Array.from(this.$el.querySelector(".attach-handler__input").files)) {
                file.toJSON = () => {
                    return {
                        lastModified: file.lastModified,
                        lastModifiedDate: file.lastModifiedDate,
                        name: file.name,
                        size: file.size,
                        type: file.type
                    }
                }

                this.fileLoading = true

                let audioFile = null

                if (file.type.includes("audio")) {
                    let audioBlob = null
                    const reader = new FileReader()
                    reader.addEventListener("load", () => {
                        audioBlob = dataURItoBlob(reader.result)
                        const audioUrl = URL.createObjectURL(audioBlob)
                        const audio = new Audio(audioUrl)
                        audioFile = {
                            audioBlob,
                            audioUrl,
                            audio,
                            file: file,
                            id: new Date().getTime()
                        }
                    })
                    reader.readAsDataURL(file)
                }

                let formData = new FormData()
                let convertMedia = "0"

                if (window.user) {
                    const { id, id_hash } = window.user
                    formData.append("user_id", id)
                    formData.append("user_id_hash", id_hash)
                }

                if (window.current_cabinet_id) {
                    formData.append("cabinet_id", window.current_cabinet_id)
                }

                formData.append("file", file)
                formData.append("convert_media", convertMedia)

                let params = {
                    model: this.appModel
                }

                axios
                    .post(window.file_storage_url, formData, { params })
                    .then(response => {
                        let data = response.data.data

                        if (data.mime_type.match("image") || data.mime_type.match("video")) {
                            data.isDocPreviewVisible = false
                        } else {
                            data.isDocPreviewVisible = true
                        }
                        if (audioFile) {
                            data = { ...data, ...(audioFile && { url: audioFile.audioUrl }) }
                        }

                        this.$emit("save", data)
                    })
                    .catch(error => {
                        console.log(error)
                    })
                    .finally(() => {
                        this.fileLoading = false
                    })
            }

            this.$refs.file.value = null
        }
    }
}
</script>

<style scoped lang="sass">
.attach-handler
    cursor: pointer
    width: 30px
    height: 30px
    display: flex
    align-items: center
    justify-content: center
    border-radius: 4px
    background-color: #fff
    transition: .2s
    z-index: 2
    @media screen and (max-width: 640px)
        width: 24px
        height: 24px
    &:hover
        background-color: #F2F7FF
    &::v-deep
        path
            fill: #3E4755
    &.button-view
        width: 100%
        &::v-deep
            path
                fill: #3965FF
</style>
