<template>
    <div class="conformity-container">
        <template v-if="interactive && $screenWidth > 640">
            <default-description text-color="#151515" :size="16" :weight="700" :line-height="21" class="mt-20">
                {{ t("quiz.answers") }}
            </default-description>
            <draggable @end="$emit('callout')" group="answers" class="answers" :list="conformityAnswers">
                <div class="shadow answer" :key="answer.id" v-for="answer in conformityAnswers">
                    <img v-if="answer.image" :src="answer.image.url || answer.image" alt="" />
                    {{ answer.text }}
                </div>
            </draggable>
        </template>

        <default-description
            v-if="interactive"
            text-color="#151515"
            :size="16"
            :weight="700"
            :line-height="21"
            class="mt-20"
        >
            {{ t("course.drag_correct_answers") }}
        </default-description>

        <div class="statements">
            <div :key="index" v-for="(statement, index) in conformityStatements">
                <div style="width: 100%" class="relative statement-drop-container statement shadow">
                    <img v-if="statement.image" :src="statement.image.url" alt="" />
                    <flex-container class="mt-15">
                        <template>
                            <AttemptResultStudentWidget
                                class="mr-10"
                                :attempt-id="attempt && attempt.id"
                                v-if="
                                    getCommentable(statement) && getCommentable(statement).has_comments && !expertView
                                "
                                :commentable-id="getCommentable(statement).id"
                                :comments-count="getCommentable(statement).comments_count"
                                :commentable-type="'statement_id'"
                            />
                            <AttemptResultExpertWidget
                                class="mr-10"
                                :statement="statement"
                                v-bind="widget(statement)"
                                v-else-if="expertView"
                            />
                        </template>
                        <p style="min-height: 24px">{{ statement.text }}</p>
                    </flex-container>

                    <div v-click-outside="hideAllStatements" class="relative">
                        <div class="empty" v-if="!statement.answers.length">
                            {{ t($screenWidth > 640 ? "course.drag_into_area" : "course.click_to_select_answer") }}
                        </div>

                        <draggable
                            @click.native="handleStatementClick(statement)"
                            @dragover.prevent.native="entered = statement"
                            @end="$emit('callout')"
                            @dragleave.prevent.native="entered = null"
                            :class="{ 'pointer-events-none': readonly, entered: statement === entered }"
                            group="answers"
                            class="answers-container"
                            :list="statement.answers"
                        >
                            <div
                                @click.stop.prevent="handleAnswerClick(answer, statement)"
                                class="shadow answer"
                                :class="getAnswerModifier(answer)"
                                :key="answer.id"
                                v-for="answer in statement.answers"
                            >
                                <img v-if="answer.image" :src="answer.image.url || answer.image" alt="" />
                                {{ answer.text }}
                            </div>
                        </draggable>

                        <transition name="fade">
                            <div class="statements-modal" v-if="statement.is_open">
                                <default-description text-color="#151515" :size="14" :weight="700" :line-height="18">
                                    {{ t("quiz.answers") }}
                                </default-description>
                                <vue-simplebar class="mt-10 conformity-scroll">
                                    <div
                                        :key="answer.id"
                                        v-for="answer in conformityAnswers"
                                        @click="handleAnswerClick(answer, statement, true)"
                                        class="statements-modal-item mr-10"
                                    >
                                        <img
                                            style="max-width: 100%"
                                            v-if="answer.image"
                                            :src="answer.image.url || answer.image"
                                            alt=""
                                        />
                                        {{ answer.text }}
                                    </div>
                                </vue-simplebar>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </div>

        <template v-if="interactive && $screenWidth <= 640">
            <default-description text-color="#151515" :size="16" :weight="700" :line-height="21" class="mt-5">
                {{ t("quiz.answers") }}
            </default-description>
            <draggable @end="$emit('callout')" group="answers" class="answers" :list="conformityAnswers">
                <div class="shadow answer" :key="answer.id" v-for="answer in conformityAnswers">
                    <img v-if="answer.image" :src="answer.image.url || answer.image" alt="" />
                    {{ answer.text }}
                </div>
            </draggable>
        </template>

        <template v-if="!interactive && typeShow === 'full_information' && !expertView">
            <p class="practice-question-title mt-30 mb-15">
                {{ t("quiz.correct_answer") }}
            </p>
            <div class="statements">
                <div :key="index" v-for="(statement, index) in statements">
                    <div style="width: 100%" class="relative statement-drop-container statement shadow">
                        <img v-if="statement.image" :src="statement.image.url" alt="" />
                        <flex-container class="mt-15">
                            <template>
                                <AttemptResultStudentWidget
                                    class="mr-10"
                                    :attempt-id="attempt && attempt.id"
                                    v-if="
                                        getCommentable(statement) &&
                                        getCommentable(statement).has_comments &&
                                        !expertView
                                    "
                                    :commentable-id="getCommentable(statement).id"
                                    :comments-count="getCommentable(statement).comments_count"
                                    :commentable-type="'statement_id'"
                                />
                                <AttemptResultExpertWidget
                                    class="mr-10"
                                    :statement="statement"
                                    v-bind="widget(statement)"
                                    v-else-if="expertView"
                                />
                            </template>
                            <p style="min-height: 24px">{{ statement.text }}</p>
                        </flex-container>

                        <div class="relative">
                            <div class="empty" v-if="!statement.answers.length">
                                {{ t($screenWidth > 640 ? "course.drag_into_area" : "course.click_to_select_answer") }}
                            </div>

                            <div class="answers-container pointer-events-none">
                                <div
                                    class="shadow answer green"
                                    :key="answer.id"
                                    v-for="answer in getOnlyCorrectAnswers(statement)"
                                >
                                    <img v-if="answer.image" :src="answer.image.url || answer.image" alt="" />
                                    {{ answer.text }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <PracticeCorrectStatusAlert
            v-if="!interactive"
            :is-correct="isCorrect"
            :is-incorrect="isIncorrect"
            :is-skipped="isSkipped"
            :is-not-started="isNotStarted"
        />
    </div>
</template>

<script>
import ConformityMixin from "@components/Practice/PracticeQuestions/components/mixins/conformity-mixin"
import PracticeCorrectStatusAlert
    from "@components/Practice/PracticeQuestions/components/PracticeCorrectStatusAlert.vue"

import cloneDeep from "lodash.clonedeep"
import draggable from "vuedraggable"

import FlexContainer from "../../../Containers/FlexContainer"
import DefaultDescription from "../../../Typography/DefaultDescription"

import AttemptResultExpertWidget from "../../common/result/AttemptResultExpertWidget"
import AttemptResultStudentWidget from "../../common/result/AttemptResultStudentWidget"

import ClickOutside from "vue-click-outside"
import { VueSimplebar } from "vue-simplebar"

export default {
    components: {
        PracticeCorrectStatusAlert,
        FlexContainer,
        AttemptResultExpertWidget,
        AttemptResultStudentWidget,
        DefaultDescription,
        draggable,
        VueSimplebar
    },
    mixins: [ConformityMixin],
    props: {
        isAllAnswersChoice: {
            type: Boolean,
            default: false
        },
        statements: {
            type: Array,
            default: () => []
        },
        answers: {
            type: Array,
            default: () => []
        },
        binds: {
            type: Object,
            default: () => {}
        },
        results: {
            type: Array,
            default: () => []
        },
        readonly: {
            type: Boolean,
            default: false
        },
        viewable: {
            type: Boolean,
            default: false
        },
        interactive: {
            type: Boolean,
            default: false
        },
        question: {
            type: Object,
            default: () => {}
        },
        typeShow: {
            type: String,
            default: ""
        },
        expertView: {
            type: Boolean,
            default: false
        },
        attempt: {
            type: Object,
            default: () => {}
        },
        lesson: {
            type: Object,
            default: () => {}
        },
        quiz: {
            type: Object,
            default: () => {}
        }
    },
    directives: { ClickOutside },
    name: "PracticeConformityManyToOne",
    data() {
        return {
            conformityAnswers: [],
            conformityStatements: [],
            entered: null
        }
    },
    created() {
        this.conformityStatements = cloneDeep(this.statements).map(statement => ({ ...statement, is_open: false }))

        if (this.question.is_required) {
            this.question.is_allowed = false
            this.$emit("update:action")
        }

        for (const statement of this.conformityStatements) {
            if (this.interactive && !this.viewable) {
                statement.answers = []
            } else if (this.results) {
                statement.answers = this.results
                    .filter(result => result.quiz_statement_id === statement.id && result.quiz_answer_id)
                    .map(result => ({ ...result, ...this.answers.find(answer => answer.id === result.quiz_answer_id) }))
            } else {
                statement.answers = []
            }
        }

        const predefinedAnswers = this.conformityStatements.reduce((accumulator, statement) => {
            if (statement.answers) {
                accumulator.push(...statement.answers)
            }
            return accumulator
        }, [])

        this.conformityAnswers = cloneDeep(
            this.answers.filter(answer => !predefinedAnswers.find(a => a.id === answer.id))
        )
    },
    methods: {
        getOnlyCorrectAnswers(statement) {
            return statement.answers.filter(answer => answer.is_correct)
        },
        hideAllStatements(ev) {
            if (ev && ev.target && ev.target.classList.contains("statements-modal-item")) {
                return
            }

            for (const statement of this.conformityStatements) {
                statement.is_open = false
            }
        },
        handleStatementClick(statement) {
            if (this.$screenWidth <= 640 || this.isAllAnswersChoice) {
                this.hideAllStatements()
                setTimeout(() => {
                    statement.is_open = true

                    this.$forceUpdate()
                }, 10)
            }
        },
        handleAnswerClick(answer, statement, push = false) {
            if (!push) {
                const index = statement.answers.indexOf(answer)
                this.conformityAnswers.splice(this.conformityAnswers.length, 0, answer)
                statement.answers.splice(index, 1)
            } else {
                if (!this.isAllAnswersChoice || this.conformityAnswers.length === 1) {
                    this.hideAllStatements()
                }

                const index = this.conformityAnswers.indexOf(answer)
                statement.answers.splice(statement.answers.length, 0, answer)
                this.conformityAnswers.splice(index, 1)
                this.$forceUpdate()
            }
            this.$emit("callout")
        },
        widget(statement) {
            return {
                expertView: this.expertView,
                typeShow: this.quiz?.result_attempt?.type_show,
                lessonId: this.lesson?.id || 0,
                questionId: this.question.id,
                question: this.question,
                attemptId: this.attempt?.id || 0,
                statement
            }
        },
        getCommentable(statement) {
            return statement
        },
        getAnswerModifier(answer) {
            if (this.interactive && this.readonly) {
                return "pointer-events-none"
            } else if (this.interactive) {
                return ""
            }

            if (this.typeShow === "simple_answer" || this.typeShow === "only_correct_or_not_question") {
                return "pointer-events-none"
            }
            const result = this.results.find(result => result.quiz_answer_id === answer.id)

            if (result) {
                return result.is_correct ? "pointer-events-none green" : "pointer-events-none red"
            }
        }
    },
    watch: {
        conformityStatements: {
            deep: true,
            handler() {
                this.question.conformityStatements = this.conformityStatements
            }
        },
        conformityAnswers: {
            deep: true,
            handler(val) {
                this.entered = null
                if (this.question.is_required) {
                    this.question.is_allowed = val.length !== this.answers.length
                    this.$emit("update:action")
                }
            }
        }
    }
}
</script>

<style lang="sass" scoped>
.conformity-scroll
    max-height: 190px
.answers
    margin-top: 10px
    display: flex
    flex-wrap: wrap
    grid-gap: 15px 15px

.statements
    margin-top: 15px
    display: grid
    grid-template-columns: 1fr 1fr
    grid-gap: 15px 40px
    @media (max-width: 640px)
        display: block
        .statement
            margin-bottom: 15px
            padding: 10px

.answer
    padding: 20px 15px
    width: max-content
    border: 1px solid transparent

    &:hover
        border-color: rgba(57, 101, 255, 1)

    img
        max-height: 250px

.shadow
    box-shadow: 0 0 15px rgba(128, 158, 191, 0.15)
    border-radius: 10px
    font-size: 13px
    cursor: pointer
    line-height: 17px
    color: #151515
    border: 1px solid transparent
    background: #fff

    &.red
        background: #FFF6F6
        border-color: #DD4141

    &.green
        background: #F9FEFC
        border-color: #15A06E

.statement
    border-radius: 7px
    padding: 10px 20px 20px
    cursor: default

    img
        height: auto
        max-width: 300px
        display: block
        margin: 0 auto 10px

.answers-container
    margin-top: 18px
    min-height: 81px
    background: #00000003
    border: 1px dashed #98A2B0
    border-radius: 7px
    position: relative
    display: flex
    flex-wrap: wrap
    grid-gap: 15px 10px
    padding: 10px

    &.entered
        border-color: rgba(57, 101, 255, 1)

.empty
    position: absolute
    font-weight: 500
    color: #B5C1D2
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    pointer-events: none
    z-index: 5
    text-align: center
</style>
